import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityModel } from '@app/shared/models/entity.model';

@Component({
  selector: 'app-bulk-card',
  templateUrl: './bulk-card.component.html',
  styleUrls: ['./bulk-card.component.scss']
})
export class EntityBulkCardComponent implements OnInit, OnDestroy {
  @Input() item: EntityModel = null;
  @Output() selectedItem = new EventEmitter<EntityModel>();
  @Output() removedItem = new EventEmitter<EntityModel>();

  public showMore = false;
  public itemChecked = false;
  public pendingMatchesEntityCardSubs = new Subscription();

  constructor() {}

  ngOnDestroy(): void {
    this.pendingMatchesEntityCardSubs.unsubscribe();
  }

  ngOnInit(): void {}

  public selectLookupRecord(event, item: EntityModel) {
    const statusChck = event.currentTarget.checked;
    if (statusChck) {
      this.selectedItem.emit(item);
    } else {
      this.removedItem.emit(item);
    }
  }
}
