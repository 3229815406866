<div class="row mx-0"> 
    <button class="btn px-0" (click)="isEntityInfoCollapse = !isEntityInfoCollapse">
        <i class="far mr-3" [ngClass]="{'fa-chevron-up': !isEntityInfoCollapse, 'fa-chevron-down': isEntityInfoCollapse}"></i>
        {{detailTitle}}
    </button>
</div>          
<div [collapse]="isEntityInfoCollapse" 
    [isAnimated]="true" 
    class="mx-0 mb-3 pt-3 rounded record--detail">
    <div class="row mx-0">
        <ng-container *ngFor="let item of detail">
            <div [ngClass]="item.cssClass ?  item.cssClass : 'col-md-4 mb-2'">
                <label>{{item.label | translate}}</label>
                <p>{{item.text}}</p>
            </div>
        </ng-container>
    </div>                
</div>