<div class="modal-header">
    <h5 class="modal-title" id="contactDeleteModalLabel" *ngIf="title">{{title}}</h5>
    <h5 class="modal-title" id="contactDeleteModalLabel" *ngIf="titleTranslation">{{titleTranslation | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close('cancel')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p *ngIf="message">{{message  | translate}}</p>
    <p *ngIf="messageHtml" [innerHtml]="messageHtml  | translate"></p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close('cancel')" *ngIf="showCancelButton">{{actionCancel | translate}}</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close('ok')" *ngIf="showOkButton">{{actionOk | translate}}</button>
</div>