import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PaginationModel } from '@app-shared/models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges{
  @Input() first: number;
  @Input() last: number;
  @Input() currentPage = 0;
  @Input() totalElements: number;
  @Input() reset: boolean;
  @Output() pageChanged = new EventEmitter<PaginationModel>();

  pageSize = 10;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reset && changes.reset.currentValue === true) {
      this.pageSize = 10;
      this.currentPage = 0;
    }
  }

  previous() {
    this.currentPage--;
    this.goToPage();
  }

  next() {
    this.currentPage++;
    this.goToPage();
  }

  private goToPage() {
    this.pageChanged.emit({ page: this.currentPage, pageSize: this.pageSize });
  }

  isNextDisabled() {
    const totalPages = this.pageSize && this.pageSize > 0 ? Math.ceil(this.totalElements / this.pageSize) : 0;
    const lastPage = totalPages - 1;
    if (this.currentPage >= lastPage) {
      return true;
    }
    return false;
  }

  changePageSize() {
    this.currentPage = 0;
    this.pageChanged.emit({ page: this.currentPage, pageSize: this.pageSize });
  }

}
