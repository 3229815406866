import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstant } from './app-routing.const';
import { AccessDeniedComponent } from '@app-shared/components/access-denied/access-denied.component';

const routes: Routes = [
  {
    path: RouteConstant.APP_CONST_SECURE,
    loadChildren: () => import('./module-authorization/authorization.module').then(m => m.AuthorizationModule)
  },
  {
    path: RouteConstant.APP_CONST_EMPTY,
    redirectTo: RouteConstant.APP_CONST_SECURE,
    pathMatch: 'full'
  },
  {
    path: RouteConstant.APP_CONST_ACCESSDENIED,
    component: AccessDeniedComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
