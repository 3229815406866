import { HttpClient } from '@angular/common/http';
import { mergeObjectsRecursively } from '@app-shared/const/orm-http-translate.const';
import { HttpTranslateResource } from '@app-shared/interfaces/http-translate-resource.interface';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
    resources: HttpTranslateResource[];
    withCommon: boolean;

    constructor(
      private readonly http: HttpClient,
      { resources, withCommon = true }: { resources: HttpTranslateResource[], withCommon?: boolean }
    ) {
      this.resources = resources;
      this.withCommon = withCommon;
    }

    getTranslation(lang: string): Observable<Record<string, unknown>> {
      let resources: HttpTranslateResource[] = [...this.resources];

      if (this.withCommon) {
        resources = [
          ...resources
        ];
      }

      return forkJoin(resources.map((config: HttpTranslateResource) => {
        return this.http.get<Record<string, unknown>>(`${config.prefix}${config.suffix}`);
      })).pipe(
        map((response: Record<string, unknown>[]) =>
        {
          return mergeObjectsRecursively(response);
        })
      );
    }
  }
