import { PaginationServiceModel } from '@app-shared/models/pagination-service';
import moment from 'moment';

export class FilterSearchModel extends PaginationServiceModel {
    id: string;
    name: string;
    address: string;
    statusApproved: boolean;
    statusCompleted: boolean;
    statusWorking: boolean;
    statusPending: boolean;
    statusRejected: boolean;
    statusDeleted: boolean;
    statusBusiness: boolean;
    statusException: boolean;
    statusUpdated: boolean;
    statusAdded: boolean;
    review: string;
    rowUpdate: string;
    systemEntityId: string;
    iata: string;

    constructor(data = null)
    {
      super();

      if (data != null) {
        this.id = data.id;
        this.name = data.name;
        this.address = data.address;
        this.statusApproved = data.statusApproved;
        this.statusCompleted = data.statusCompleted;
        this.statusWorking = data.statusWorking;
        this.statusPending = data.statusPending;
        this.statusRejected = data.statusRejected;
        this.statusDeleted = data.statusDeleted;
        this.statusBusiness = data.statusBusiness;
        this.statusException = data.statusException;
        this.statusUpdated = data.statusUpdated;
        this.statusAdded = data.statusAdded;
        this.review = data.review;
        this.systemEntityId = data.systemEntityId;
        this.iata = data.iata;
        this.rowUpdate = data.rowUpdate && data.rowUpdate !== '' ? moment(data.rowUpdate).format('YYYY-MM-DD') : '';
      }
    }
}

export class FilterSearchQueryModel {
    id: string;
    name: string;
    address: string;
    onyx_system_entity_id: string;
    iata: string;
    status_approved: boolean;
    status_completed: boolean;
    status_working: boolean;
    status_pending: boolean;
    status_rejected: boolean;
    status_deleted: boolean;
    status_updated: boolean;
    status_business: boolean;
    status_exception: boolean;
    status_added: boolean;
    review: string;
    row_update: string;
    row_update_date: string;
    total_elements_per_page = 10;
    page_number = 0;


    constructor(data: FilterSearchModel = null)
    {
      if (data != null) {
        this.id = data.id;
        this.name = data.name;
        this.address = data.address;
        this.status_approved = data.statusApproved;
        this.status_completed = data.statusCompleted;
        this.status_working = data.statusWorking;
        this.status_pending = data.statusPending;
        this.status_rejected = data.statusRejected;
        this.status_deleted = data.statusDeleted;
        this.status_updated = data.statusUpdated;
        this.status_added = data.statusAdded;
        this.status_business = data.statusBusiness;
        this.status_exception = data.statusException;
        this.review = data.review;
        this.row_update = data.rowUpdate;
        this.onyx_system_entity_id = data.systemEntityId;
        this.iata = data.iata;
        this.page_number = data.pageNumber;
        this.total_elements_per_page = data.totalElementsPerPage;
      }
    }
}

export class ReviewStatusShow {
  approved = true;
  completed  = true;
  work  = true;
  pending  = true;
  rejected  = false;
  deleted  = false;
  business  = false;
  exeption  = false;
  updated  = false;
  added  = false;

  constructor(data = null) {
    if (data) {
      this.approved = data.approved ?? true;
      this.completed = data.completed ?? true;
      this.work = data.work ?? true;
      this.pending = data.pending ?? true;
      this.rejected = data.rejected ?? false;
      this.deleted = data.deleted ?? false;
      this.business = data.business ?? false;
      this.exeption = data.exeption ?? false;
      this.updated = data.updated ?? false;
      this.added = data.added ?? false;
    }
  }
}

export class FilterSearchShow {
  public showSystemEntityId = false;
  public showIata = false;

  constructor(data: {showSystemEntityId, showIata} = null) {
    if (data) {
      this.showSystemEntityId = data.showSystemEntityId;
      this.showIata = data.showIata;
    }
  }
}
