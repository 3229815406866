import { Injectable } from '@angular/core';
import { IFilterSearch } from '@app-shared/interfaces/filter-search.interface';
import { Subject } from 'rxjs';
import { EntityModel } from '../../models/entity.model';

@Injectable({
  providedIn: 'root'
})
export class NewSearchListenerService {

  eventClearSelectionSub = new Subject<IFilterSearch>();
  itemeSelectionSub = new Subject<{searchType: string, item: EntityModel}>();

  constructor() { }

  setClearSelection(){
    this.eventClearSelectionSub.next();
  }

  getClearSelection(){
    return this.eventClearSelectionSub.asObservable();
  }

  setItemSelection(searchType: string, item: EntityModel){
    this.itemeSelectionSub.next({searchType, item});
  }

  getItemSelection(){
    return this.itemeSelectionSub.asObservable();
  }
}
