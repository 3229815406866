import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserPermission } from '../model/user-permissions';
import { JWT_TOKEN_STORAGE_KEY } from '@onyx/core';
import { TOKEN_PERMISSIONS_NAME } from '@app-auth/const/token.const';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenModel } from '@app-auth/model/token.model';
import { JwtTokenManagementService } from './jwt-token-management.service';
import { map } from 'rxjs/operators';
import { ActionPermissionsModel } from '@app-auth/model/action-permissions';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenManagementStore {

  public validateTokenObs: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _permissionList: string[] = [];

  constructor(private jwtHelperService: JwtHelperService,
              private jwtTokenManagementService: JwtTokenManagementService) {
    this.getPermissions();
  }

  setToken(token){
    localStorage.setItem(JWT_TOKEN_STORAGE_KEY, token);
  }

  getToken(): string{
    return localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
  }

  getTokenInfo(): TokenModel{
    const token = this.getToken();
    const decodedToken: TokenModel = this.jwtHelperService.decodeToken(token);
    return decodedToken;
  }

  getUserName(): string {
    const token = this.getTokenInfo();
    return token ? token.jti : 'Jane Doe';
  }

  getTokenExpirationDate(): Date {
    return this.jwtHelperService.getTokenExpirationDate(this.getToken());
  }

  isTokenExpired() {
    return this.jwtHelperService.isTokenExpired();
  }

  getUserPermissions(token: string): Observable<UserPermission> {
    return this.jwtTokenManagementService.getUserPermissions(token).pipe(map(item => {
      const permissionList = this.createPermissionArray(item);
      const permissionData = new UserPermission({
        token,
        permissions: permissionList
      });
      this.setLocalPermissions(permissionData);
      return permissionData;
    }));

  }

  setLocalPermissions(userPermission: UserPermission) {
    localStorage.setItem(TOKEN_PERMISSIONS_NAME, JSON.stringify(userPermission.permissions));
    this.getPermissions();
  }

  getLocalPermissions(): string[]{
    return this._permissionList;
  }

  refreshSession(): Observable<string>{
   return this.jwtTokenManagementService.refreshSession(this.getToken());
  }

  removeSession() {
    localStorage.clear();
  }

  hasUserPermission(permission: string){
    return this._permissionList.find(t => t === permission);
  }

  private createPermissionArray(actions: ActionPermissionsModel): string[] {
    const permissionList = [];
    actions.output.groups.map(group => group.policies.map(policy => permissionList.push(policy.id)));
    return permissionList;
  }

  private getPermissions() {
    const userPermissionsLocal =  localStorage.getItem(TOKEN_PERMISSIONS_NAME);
    this._permissionList = JSON.parse(userPermissionsLocal);
  }
}
