import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterSearchInterface } from '@app-shared/interfaces/filter-search-store.interface';
import { FilterSearchModel, FilterSearchShow, ReviewStatusShow } from '@app-shared/models/filter-search';
import { FilterSearchListenerService } from '@app-shared/services/filter-search-listener.service';
import { FormValidatorsService } from '@app-shared/services/form-validators.service';
import { Observable, Subscriber, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit {

  @Input() searchStore: FilterSearchInterface<any>;
  @Input() showStatusFilter: ReviewStatusShow = new ReviewStatusShow();
  @Input() showFilterSerachFields: FilterSearchShow = new FilterSearchShow();
  @Input() labelTranslate = { name: 'APP.FILTER_ENTITY_NAME',  id: 'APP.FILTER_ENTITY_ID', reviewStatus: 'APP.FILTER_REVIEW_STATUS' };
  @Output() searchListEvent = new EventEmitter<FilterSearchModel>();

  public searchFilterForm: FormGroup;
  public selectedRowUpdateDate: Date = null;
  public filterName: Observable<string[]>;
  public filterAddress: Observable<string[]>;
  public filterSearchSubs = new Subscription();
  constructor(private formBuilder: FormBuilder,
              private _filterSearchListenerService: FilterSearchListenerService
  ) { }

  ngOnInit(): void {
    this.createFilterForm();
    this.createSubsriptions();
  }

  private createFilterForm() {
    this.searchFilterForm = this.formBuilder.group({
      id : ['', [Validators.maxLength(50)]],
      name: ['', [Validators.maxLength(100)]],
      address: ['', [Validators.maxLength(500)]],
      statusApproved: [false],
      statusCompleted: [false],
      statusWorking: [false],
      statusPending: [false],
      statusRejected: [false],
      statusDeleted: [false],
      statusBusiness: [false],
      statusException: [false],
      statusUpdated: [false],
      statusAdded: [false],
      review: [''],
      rowUpdate: [this.selectedRowUpdateDate, [FormValidatorsService.isDate]],
      systemEntityId: ['', [Validators.maxLength(50)]]
    });
  }

  private createSubsriptions() {
    this._filterSearchListenerService.getFilterSearchForm().subscribe(data => {
      if (data) {
        if (data.clearForm) {
          this.resetSearchFilters();
        }
        if (data.id !== undefined) {
          this.searchFilterForm.get('id').setValue(data.id);
        }

        if (data.name !== undefined) {
          this.searchFilterForm.get('name').setValue(data.name);
        }

        if (data.address !== undefined) {
          this.searchFilterForm.get('address').setValue(data.address);
        }

        if (data.statusApproved !== undefined) {
          this.searchFilterForm.get('statusApproved').setValue(data.statusApproved);
        }

        if (data.statusCompleted !== undefined) {
          this.searchFilterForm.get('statusCompleted').setValue(data.statusCompleted);
        }

        if (data.statusWorking !== undefined) {
          this.searchFilterForm.get('statusWorking').setValue(data.statusWorking);
        }

        if (data.statusPending !== undefined) {
          this.searchFilterForm.get('statusPending').setValue(data.statusPending);
        }

        if (data.statusRejected !== undefined) {
          this.searchFilterForm.get('statusRejected').setValue(data.statusRejected);
        }

        if (data.statusDeleted !== undefined) {
          this.searchFilterForm.get('statusDeleted').setValue(data.statusDeleted);
        }

        if (data.statusBusiness !== undefined) {
          this.searchFilterForm.get('statusBusiness').setValue(data.statusBusiness);
        }

        if (data.statusException !== undefined) {
          this.searchFilterForm.get('statusException').setValue(data.statusException);
        }

        if (data.statusUpdated !== undefined) {
          this.searchFilterForm.get('statusUpdated').setValue(data.statusUpdated);
        }

        if (data.statusAdded !== undefined) {
          this.searchFilterForm.get('statusAdded').setValue(data.statusAdded);
        }

        this.applySearchFilters();
      }
    });

    this.filterAddress = new Observable((observer: Subscriber<string>) => {
                          observer.next(this.searchFilterForm.get('address').value);
                      }).pipe(
                        startWith(''),
                        debounceTime(300),
                        distinctUntilChanged(),
                        mergeMap((val: string) => this.getAddresssAsObservable(val)));

    this.filterName = new Observable((observer: Subscriber<string>) => {
                            observer.next(this.searchFilterForm.get('name').value);
                        }).pipe(
                          startWith(''),
                          debounceTime(300),
                          distinctUntilChanged(),
                          mergeMap((val: string) => this.getNamesAsObservable(val)));


  }

  public getNamesAsObservable(val: string): Observable<string[]> {
    if (val.length < 3) { return of ([]); }
    return this.searchStore.searchAutoCompleteName(val);
  }

  public getAddresssAsObservable(val: string): Observable<string[]> {
    if (val.length < 3) { return of ([]); }
    return this.searchStore.searchAutoCompleteAddress(val);
  }

  public onSelectRowUpdateDate(ev: Date) {
    if (ev && this.searchFilterForm.get('rowUpdate').invalid) {
      this.selectedRowUpdateDate = null;
    }
    this.searchFilterForm.get('rowUpdate').setValue(ev);
  }

  public onClickRowUpdate(ev: Date) {
    this.searchFilterForm.get('rowUpdate').markAsTouched();
  }

  public isInValid(field: string) {
    if (this.searchFilterForm.get(field) !== null) {
      return (
        this.searchFilterForm.get(field).touched &&
        this.searchFilterForm.get(field).errors
      );
    }
  }

  public resetSearchFilters() {
    this.createFilterForm();
    this.selectedRowUpdateDate = null;
  }

  public applySearchFilters(){
    if (this.searchFilterForm.valid) {
      this.searchListEvent.emit(new FilterSearchModel(this.searchFilterForm.value));
    }
  }

  get rowUpdate() { return this.searchFilterForm.get('rowUpdate'); }

}
