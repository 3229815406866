import { Injectable } from '@angular/core';
import { IFilterSearch } from '@app-shared/interfaces/filter-search.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterSearchListenerService {

  filterSeachFormSub = new Subject<IFilterSearch>();

  constructor() { }

  setFilterSearchForm(form: IFilterSearch){
    this.filterSeachFormSub.next(form);
  }

  getFilterSearchForm(){
    return this.filterSeachFormSub.asObservable();
  }
}
