import { Component, Input, OnInit } from '@angular/core';
import { RemappingDetailModel } from '@app-shared/models/remapping-detail.model';

@Component({
  selector: 'app-remapping-detail-data',
  templateUrl: './remapping-detail-data.component.html',
  styleUrls: ['./remapping-detail-data.component.scss']
})
export class RemappingDetailDataComponent implements OnInit {
  @Input() detailTitle: string;
  @Input() detail: RemappingDetailModel[];

  public isEntityInfoCollapse = false;

  constructor() { }

  ngOnInit(): void {
  }

}
