import { environment } from '@app-env/environment';

export const ALERT_WARNING = 'alert alert-toast-warning';
export const ALERT_WARNING_ICO = 'fa-hand-paper';
export const ALERT_WARNING_TIMEOUT = environment.alert.alertWarningTimeout;

export const ALERT_DANGER = 'alert alert-toast-danger';
export const ALERT_DANGER_ICO = 'fa-exclamation-triangle';
export const ALERT_DANGER_TIMEOUT = environment.alert.alertErrorTimeout;

export const ALERT_SUCCESS = 'alert alert-toast-success';
export const ALERT_SUCCESS_ICO = 'fa-exclamation-triangle';
export const ALERT_SUCCESS_TIMEOUT = environment.alert.alertSuccessTimeout;

export const ALERT_INFO = 'alert alert-toast-info';
export const ALERT_INFO_ICO = 'fa-exclamation-info';
export const ALERT_INFO_TIMEOUT = environment.alert.alertInfoTimeout;
