import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {

  static isDate(formControl: AbstractControl): { [key: string]: boolean } | null {
    if (!formControl.value) {
      return null;
    }
    const isInvalid = isNaN(formControl.value?.getDate());
    if (isInvalid) {
      return { invalidDate: true };
    }
    return null;
  }
}
