
<ng-container *ngIf="createMasterDetail">
    <div class="modal-header">
        <h5 class="modal-title" id="edit-record-modal-title" >{{'APP.CREATE_NEW_MASTER' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <ng-container *ngIf="!isPreviewBlocked">
        <ng-container *ngTemplateOutlet="createMasterSaved ? newMaster: createNewMaster"></ng-container>
       </ng-container>
       <ng-container *ngIf="isPreviewBlocked">
        <div class="alert alert-danger" role="alert">
            <div class="alert-message">
                <i class="far fa-exclamation-triangle"></i>  {{createMasterErrorMessage}}
            </div>
          </div>
       </ng-container>
    </div>
    <div class="modal-footer">
        <ng-container *ngTemplateOutlet="createMasterSaved || isPreviewBlocked ? newMasterActions: createNewMasterActions"></ng-container>
    </div>
</ng-container>

<ng-template #createNewMaster>
    <div>
        <p>{{'APP.PENDING_MATCHES.CREATE_NEW_MASTER_DESCRIPTION' | translate}}</p>
        <div class="row">
            <div class="col-md-12">
                <app-entity-card-selected [item]="createMasterDetail">
                    <ng-container entity-title>
                        <h3 class="card-title">{{newMasterId}}</h3>
                    </ng-container>
                    <ng-container entity-content>
                        <p class="mb-1" *ngIf="createMasterDetail.entityName"><strong>{{'APP.CREATE_NEW_MASTER.NAME' | translate}}: </strong><small>{{createMasterDetail.entityName || ''}}</small></p>
                        <p class="mb-1" *ngIf="createMasterDetail.onyxApplicationId"><strong>{{'APP.CREATE_NEW_MASTER.APPLICATION_ID' | translate}}: </strong><small>{{createMasterDetail.onyxApplicationId || ''}}</small></p>
                        <p class="mb-1" *ngIf="createMasterDetail.entityAddress1"><strong>{{'APP.CREATE_NEW_MASTER.ENTITY_ADDRESS' | translate}}: </strong><small>{{createMasterDetail.entityAddress1 || '--'}}</small></p>
                        <p class="mb-1" *ngIf="createMasterDetail.onyxGroupId"><strong>{{'APP.CREATE_NEW_MASTER.GROUP_ID' | translate}}: </strong><small>{{createMasterDetail.onyxGroupId || ''}}</small></p>
                        <p class="mb-1" *ngIf="createMasterDetail.onyxSubgroupId"><strong>{{'APP.CREATE_NEW_MASTER.SUBGROUP_ID' | translate}}: </strong><small>{{createMasterDetail.onyxSubgroupId || ''}}</small></p>
                        <p class="mb-1" *ngIf="createMasterDetail.onyxSystemGroupId"><strong>{{'APP.CREATE_NEW_MASTER.SYSTEM_GROUP_ID' | translate}}: </strong><small>{{createMasterDetail.onyxSystemGroupId || ''}}</small></p>
                        <p class="mb-1" *ngIf="createMasterDetail.onyxSystemSubgroupId"><strong>{{'APP.CREATE_NEW_MASTER.SYSTEM_SUBGROUP_ID' | translate}}: </strong><small>{{createMasterDetail.onyxSystemSubgroupId || ''}}</small></p>
                    </ng-container>              
                </app-entity-card-selected>
            </div>
        </div>
    </div>
    <div>
        <div class="form-group">
            <label for="textareaInput">{{'APP.PENDING_MATCHES.CREATE_MASTER.REVIEW_NOTES' | translate}}</label>
            <textarea class="form-control" rows="2" id="textareaInput" maxlength="4000" [(ngModel)]="reviewNotes"></textarea>
        </div>
    </div>
    
</ng-template>

<ng-template #newMaster>
    <div class="row mx-0">
        <p>
            {{'APP.PENDING_MATCHES.NEW_MASTER_CREATED' | translate}}
        </p>
    </div>
    <div class="row mx-0 justify-content-center">
        <div class="filter-tag"
            [cdkCopyToClipboard]="newMasterId" 
            (cdkCopyToClipboardCopied)="onClipboardCopy(newMasterId)">
            <span class="filter-tag-text new-master-tag">{{newMasterId}}</span>
            <i class="fa fa-copy ml-2" aria-hidden="true"></i>
        </div>
    </div>
</ng-template>

<ng-template #createNewMasterActions>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">{{'APP.BUTTON.DECLINE' | translate}}</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="save()" [disabled]="!newMasterId">{{'APP.BUTTON.ACCEPT' | translate}}</button>
</ng-template>

<ng-template #newMasterActions>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">{{'APP.BUTTON.CLOSE' | translate}}</button>
</ng-template>