import { Component, Input, OnInit } from '@angular/core';
import { OrmStatusReviewS } from '@app-shared/const/orm-pending-list';

@Component({
  selector: 'app-status-tag',
  templateUrl: './status-tag.component.html',
  styleUrls: ['./status-tag.component.scss']
})
export class StatusTagComponent implements OnInit {

  @Input() type: OrmStatusReviewS;

  public elClass = '';
  public elTranslation = '';

  constructor() { }

  ngOnInit(): void {
    this.setNewElementClass(this.type);
  }

  private setNewElementClass(type: OrmStatusReviewS) {
    switch (type){
      case OrmStatusReviewS.APPROVED:
        this.elClass = 'badge-success';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_A';
        break;
      case OrmStatusReviewS.COMPLETED:
        this.elClass = 'badge-warning';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_C';
        break;
      case OrmStatusReviewS.WORKING_IN_PROGRESS:
        this.elClass = 'badge-info';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_W';
        break;
      case OrmStatusReviewS.PENDING:
        this.elClass = 'badge-danger';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_P';
        break;
      case OrmStatusReviewS.REJECTED:
          this.elClass = 'badge-secondary';
          this.elTranslation = 'APP.FILTER_REVIEW_STATUS_R';
          break;
      case OrmStatusReviewS.DELETED:
        this.elClass = 'badge-secondary';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_D';
        break;
      case OrmStatusReviewS.UPDATED:
        this.elClass = 'badge-danger';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_U';
        break;
      case OrmStatusReviewS.ADDED:
        this.elClass = 'badge-success';
        this.elTranslation = 'APP.FILTER_REVIEW_STATUS_AD';
        break;
    }
  }
}
