import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { JwtTokenManagementStore } from '@app-auth/services/jwt-token-management.store';

@Directive({
  selector: '[iamNotInSecurity]'
})
export class OnyxIamNotInSecurityDirective {

  private _iamNotInSecurity: string;

  private permissionList: string[];

  constructor(private templateRef: TemplateRef<any>,
              private jwtTokenManagementStore: JwtTokenManagementStore,
              private viewContainer: ViewContainerRef) {
  }


  @Input()
  set iamNotInSecurity(iamSecurity: string) {
    if (!iamSecurity) {
      this.viewContainer.clear();
    }
    else {
      this._iamNotInSecurity = iamSecurity;
      this.permissionList = this.jwtTokenManagementStore.getLocalPermissions();

      if (this.permissionList && (this.permissionList.length > 0)) {
          if (!this.theUserCanSeeTheComponent()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

  public theUserCanSeeTheComponent(): boolean {
    return this.permissionList.includes(this._iamNotInSecurity);
  }

}
