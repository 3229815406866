<form [formGroup]="searchFilterForm" (keydown.enter)="$event.preventDefault()">
    <div class="filter">
        <div class="filter-content-no-tab">
          <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="entity-name">{{ labelTranslate.name| translate}}</label>
                    <input  type="text" 
                            class="form-control" 
                            name="entity-name"
                            formControlName="name"
                            [typeaheadAsync]="true"
                            [typeahead]="filterName">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="entity-id">{{labelTranslate.id | translate}}</label>
                    <input  type="text" 
                            class="form-control" 
                            name="entity-id"
                            formControlName="id">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="address-line">{{'APP.FILTER_ADDRESS' | translate}}</label>
                    <input  type="text" 
                            class="form-control" 
                            name="address-line"
                            formControlName="address"
                            [typeaheadAsync]="true"
                            [typeahead]="filterAddress">
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mt-3">
                <label>{{labelTranslate.reviewStatus | translate}}</label>
                <div class="mt-2 row mx-0">
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.approved">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-approved-check" 
                                formControlName="statusApproved">
                        <label class="custom-control-label" for="status-approved-check">{{'APP.FILTER_REVIEW_STATUS_A' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.added">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-added-check" 
                                formControlName="statusAdded">
                        <label class="custom-control-label" for="status-added-check">{{'APP.FILTER_REVIEW_STATUS_AD' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-3 col-sm-12 mt-2" *ngIf="showStatusFilter.completed">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-completed-check" 
                                formControlName="statusCompleted">
                        <label class="custom-control-label" for="status-completed-check">{{'APP.FILTER_REVIEW_STATUS_C' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.work">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-work-check" 
                                formControlName="statusWorking">
                        <label class="custom-control-label" for="status-work-check">{{'APP.FILTER_REVIEW_STATUS_W' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.pending">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-pending-check" 
                                formControlName="statusPending">
                        <label class="custom-control-label" for="status-pending-check">{{'APP.FILTER_REVIEW_STATUS_P' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.business">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-business-check" 
                                formControlName="statusBusiness">
                        <label class="custom-control-label" for="status-business-check">{{'APP.FILTER_REVIEW_STATUS_B' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.exeption">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-exeption-check" 
                                formControlName="statusException">
                        <label class="custom-control-label" for="status-exeption-check">{{'APP.FILTER_REVIEW_STATUS_E' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.rejected">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-reject-check" 
                                formControlName="statusRejected">
                        <label class="custom-control-label" for="status-reject-check">{{'APP.FILTER_REVIEW_STATUS_R' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.deleted">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-deleted-check" 
                                formControlName="statusDeleted">
                        <label class="custom-control-label" for="status-deleted-check">{{'APP.FILTER_REVIEW_STATUS_D' | translate}}</label>
                    </div>
                    <div class="custom-control custom-checkbox col-lg-6 col-sm-12 mt-2" *ngIf="showStatusFilter.updated">
                        <input  type="checkbox" 
                                class="custom-control-input" 
                                id="status-updated-check" 
                                formControlName="statusUpdated">
                        <label class="custom-control-label" for="status-updated-check">{{'APP.FILTER_REVIEW_STATUS_U' | translate}}</label>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-4 mt-3">
                <label>{{'APP.FILTER_ROW_UPDATE_DATE' | translate}}</label>
                <div class="form-group" [ngClass]="{ 'form-group-invalid': isInValid('rowUpdate')}">
                    <ods-date-picker id="startDate"
                                    dateInputFormat="MM/DD/YYYY"
                                    (click)="onClickRowUpdate($event)"
                                    (selectedDateChange)="onSelectRowUpdateDate($event)"
                                    [isValid]="!isInValid('rowUpdate')"
                                    [(selectedDate)]="selectedRowUpdateDate">
                    </ods-date-picker>
                    <span class="invalid-feedback" *ngIf="rowUpdate?.errors">{{'MESSAGES.VALIDATION.DATE' | translate}}</span>
                </div>
            </div>
            <div class="col-md-4 mt-3" *ngIf="showFilterSerachFields.showSystemEntityId">
                <div class="form-group">
                    <label for="system-entity-id">{{'APP.FILTER_SYSTEM_ENTITY_ID' | translate}}</label>
                    <input  type="text" 
                            class="form-control" 
                            name="system-entity-id"
                            formControlName="systemEntityId">
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <button type="button" class="btn btn-secondary mr-3" (click)="resetSearchFilters()">{{'COMMON.NAV.EVENT_FILTERS.RESET_BUTTON' | translate}}</button>
              <button type="button" class="btn btn-primary" (click)="applySearchFilters()">{{'COMMON.NAV.EVENT_FILTERS.APPLY_BUTTON' | translate}}</button>
            </div>
          </div>
        </div>
    </div>
</form>
