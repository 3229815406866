import { Component, OnInit} from '@angular/core';
import { AlertToastTypeModel } from '@app-shared/models/alert-toast-type.model';
import { AlertToastModel } from '@app-shared/models/alert-toast.model';
import { AlertToastService } from '@app-shared/services/alert-toast.service';

@Component({
  selector: 'app-alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss']
})
export class AlertToastComponent implements OnInit {

  public messages: AlertToastModel[] = [];
  public alertTypeClass = new AlertToastTypeModel();
  public hideme = [];
  constructor(private alertToastMessagesService: AlertToastService) {}

  ngOnInit() {
    this.alertToastMessagesService.messages.subscribe(newMessage => {
      this.show(newMessage);
    });

    this.alertToastMessagesService.getToastStatus().subscribe(status => {
      switch (status.action){
        case 'close':
          this.clearNonPersistentMessages();
          break;
        default:
          console.log('Status Toast log', status);
      }
    });
  }

  public clearNonPersistentMessages(): void {
    this.messages = this.messages.filter(message => message.displayThroughNavigation);
  }

  public show(newMessage: AlertToastModel): void {
    const alertToast = this.setAlertToastStructure(newMessage);
    const alert = new AlertToastModel(alertToast);
    this.messages.push(alert);
    this.timerClose(alert);
  }

  public close(message: AlertToastModel) {
    this.remove(message);
  }

  public showClose(message: AlertToastModel): boolean {
    return !message.autoClose;
  }

  private timerClose(message: AlertToastModel) {
    if (message.autoClose) {
      setTimeout(() => {
        this.remove(message);
      }, message.timeOut);
    }
  }

  private remove(message: AlertToastModel) {
    this.messages.forEach(p => {
      if (p.id === message.id) {
        p.cssClass += ' alert-hidden';
        setTimeout(() => {
          p.removed = true;
        }, 500);
      }
    });
    setTimeout(() => {
      this.messages = this.messages.filter(p => p.removed === false || !p.removed);
    }, 500);
  }

  private setAlertToastStructure(alert: AlertToastModel) {
    const newAlertStyle = this.findClass(alert);
    alert.cssClass = newAlertStyle.className;
    alert.icoCss = newAlertStyle.ico;
    alert.timeOut = newAlertStyle.timeOut;
    alert.autoClose = newAlertStyle.autoClose;
    return alert;
  }

  private findClass(message: AlertToastModel): any {
    const type = this.alertTypeClass.getTypeList();
    return type[message.level];
  }
}
