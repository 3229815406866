<form [formGroup]="searchFilterForm" class="record--detail">
    <div class="row mx-0 pt-3">
        <div class="col-md-6 form-group">
            <label for="entity-id">
                <span class="text-capitalize">{{searchType}}</span> {{'APP.SEARCH.CONTROL_ID' | translate}}
            </label>
            <input type="text" 
                    class="form-control"
                    name="entity-id"
                    maxlength="100"
                    formControlName="onyx_entity_id">
        </div>
        <div class="col-md-6 form-group">
            <label for="entity-name">
                <span class="text-capitalize">{{searchType}}</span> {{'APP.SEARCH.CONTROL_NAME' | translate}}
            </label>
            <input type="text" 
                    class="form-control"
                    name="entity-name"
                    maxlength="100"
                    formControlName="onyx_entity_name">
        </div>
    </div>
    <div class="row mx-0">              
        <div class="col-md-9 col-lg-10 form-group">
            <label for="entity-address">
                {{'APP.SEARCH.CONTROL_ADDRESS' | translate}}
            </label>
            <input type="text" 
                    class="form-control"
                    name="entity-address"
                    maxlength="150"
                    formControlName="onyx_entity_address">
        </div>
        <div class="col-md-3 col-lg-2 text-right m-auto">
            <button class="btn btn-secondary" 
                    (click)="searchNewEntity()"
                    [disabled]="isSearcherDisabled()">
                    {{'APP.BUTTON.SEARCH' | translate}}
            </button>
        </div>
    </div>                 
</form>