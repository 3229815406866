<ng-container>
    <p class="mb-4">
        <ng-content select="[description]"></ng-content>
    </p>
    <div class="row">
        <div class="col-md-12">
            <div class="custom-control custom-checkbox d-inline-block mb-3">
                <input type="checkbox" 
                        class="custom-control-input c-pointer" 
                        id="select-all-pending-records"
                        (click)="selectAllPendingLookupRecords($event)">
                <label class="custom-control-label c-pointer" for="select-all-pending-records">
                    <strong>Select all <small *ngIf="lookupList">({{lookupList.length}})</small></strong>
                </label>
            </div>
        </div>
    </div>
    <div class="lookup-card-list--container-card search">            
        <div *ngFor="let item of lookupList">
            <app-bulk-card [item]="item" (selectedItem)="selectedItem($event)" (removedItem)="removedItem($event)"></app-bulk-card>
        </div>
    </div>
</ng-container>
