import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-card-selected',
  templateUrl: './entity-card-selected.component.html',
  styleUrls: ['./entity-card-selected.component.scss']
})
export class EntityCardSelectedComponent implements OnInit {

  @Input() item: any = null;

  constructor() { }

  ngOnInit(): void {
  }

}
