<ng-container *ngIf="counter">
    <app-status-tag [type]="statusType.APPROVED" *ngIf="showStatus.approved">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countApproved}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.ADDED" *ngIf="showStatus.added">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countAdded}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.COMPLETED" *ngIf="showStatus.completed">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countCompleted}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.DELETED" *ngIf="showStatus.deleted">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countDeleted}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.UPDATED" *ngIf="showStatus.updated">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countUpdated}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.WORKING_IN_PROGRESS" *ngIf="showStatus.work">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countWork}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.PENDING" *ngIf="showStatus.pending">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countPending}}</span>
        </ng-container>
    </app-status-tag>
    <app-status-tag [type]="statusType.REJECTED" *ngIf="showStatus.rejected">
        <ng-container status-content>
            <span class="ml-2 count-badge">{{counter.countRejected}}</span>
        </ng-container>
    </app-status-tag>
</ng-container>