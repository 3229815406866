<div class="form-inline float-right">
  <span class="d-none d-md-inline mr-3">{{'COMMON.PAGINATION.RESULTS_ITEMS_PER_PAGE' | translate}}</span>
  <select [(ngModel)]="pageSize" class="form-control mr-4" aria-label="Items Per Page" (change)="changePageSize()">
    <option [ngValue]="1">1</option>
    <option [ngValue]="5">5</option>
    <option [ngValue]="10">10</option>
    <option [ngValue]="25">25</option>
    <option [ngValue]="50">50</option>
    <option [ngValue]="100">100</option>
  </select>

  <span class="d-none d-md-inline mr-3">
    {{'COMMON.PAGINATION.RESULTS_INFO' | translate}} {{first}}-{{last}} {{'COMMON.PAGINATION.RESULTS_INFO_OF' | translate}} {{totalElements}}
  </span>

  <div class="btn-group" role="group" aria-label="Data Row Page">
    <button type="button" class="btn btn-secondary" aria-label="Previous" (click)="previous()" [disabled]="currentPage === 0">
      <i class="fas fa-caret-left"></i>
      <span class="d-none d-md-inline ml-2">{{'COMMON.PAGINATION.PREVIOUS' | translate}}</span>
    </button>
    <button type="button" class="btn btn-secondary" aria-label="Next" (click)="next()" [disabled]="isNextDisabled()">
      <span class="d-none d-md-inline mr-2">{{'COMMON.PAGINATION.NEXT' | translate}}</span>
      <i class="fas fa-caret-right"></i>
    </button>
  </div>
</div>
