export class OrmApprovedStatusReview {
    public static readonly ADDED = 'Added';
    public static readonly DELETED = 'Deleted';
    public static readonly ADDED_S = 'A';
    public static readonly DELETED_S = 'D';
}

export const STATUS_APPROVED_REVIEW = {
    A: OrmApprovedStatusReview.ADDED_S,
    D: OrmApprovedStatusReview.DELETED_S
};

export const STATUS_APPROVED_ORIGIN = {
    A: OrmApprovedStatusReview.ADDED,
    O: 'OP'
};
