import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-empty-result',
  templateUrl: './table-empty-result.component.html',
  styleUrls: ['./table-empty-result.component.scss']
})
export class TableEmptyResultComponent implements OnInit {

  @Input() marginStyle = 'mt-5';
  @Input() heading = 'APP.PENDING_MATCHES.EMPTY_LIST.TITLE';
  @Input() message = 'APP.PENDING_MATCHES.EMPTY_LIST.DESCRIPTION';

  constructor() { }

  ngOnInit(): void {
  }

}
