import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TYPE_SEARCH_NEW_MASTER } from '@app/shared/const/create-new-master.const';
import { ISearchBasic } from '@app/shared/interfaces/search-basic.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-basic-panel',
  templateUrl: './search-basic-panel.component.html',
  styleUrls: ['./search-basic-panel.component.scss']
})
export class SearchBasicPanelComponent implements OnInit {
  @Output() cleanSearch = new EventEmitter<void>();
  @Output() search = new EventEmitter<ISearchBasic>();
  @Input() searchType = TYPE_SEARCH_NEW_MASTER.entity;
  @Input() skipRestrictionsFilters = false;

  public searchFilterForm: FormGroup = new FormGroup({});
  public pendingMatchesSubs = new Subscription();
  public entitySearched = {
    id: null,
    matchCount: null
  };

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.createFilterForm();
  }

  public searchNewEntity() {
    const newEntity: ISearchBasic = {
      id: this.searchFilterForm.get('id').value,
      name: this.searchFilterForm.get('name').value,
      address: this.searchFilterForm.get('address').value,
    };
    this.cleanSearch.emit();

    if (newEntity) {
      this.entitySearched.id = newEntity.id;
      this.search.emit(newEntity);
    }
  }

  public isSearcherDisabled() {
    let statusDisabled = false;
    if (!this.skipRestrictionsFilters && (this.searchFilterForm.get('id')?.value.length === 0 &&
      this.searchFilterForm.get('name')?.value.length === 0 &&
      this.searchFilterForm.get('address')?.value.length === 0)) {
      statusDisabled = true;
    }
    return statusDisabled;
  }

  private createFilterForm() {
    this.searchFilterForm = this.formBuilder.group({
      id : ['', [Validators.maxLength(100)]],
      name: ['', [Validators.maxLength(100)]],
      address: ['', [Validators.maxLength(100)]],
    });
  }
}
