import { IEntityLookup } from '@app/module-approved/components/entity/interfaces/entity-approved-lookup.interface';
import { IEntityMaster } from '@app/shared/interfaces/entity-master.interface';
import { Guid } from '../utils/guid';

export class EntityModel implements IEntityMaster {
    onyxEntityId = '';
    onyxApplicationId = '';
    onyxApplicationName = '';
    onyxSubgroupId = '';
    onyxGroupId = '';
    onyxSubgroupName = '';
    onyxGroupName = '';
    onyxSystemSubgroupId = '';
    onyxSystemGroupId = '';
    entityName = '';
    entityDisplayName = '';
    entityAddress1 = '';
    entityAddress2 = '';
    entityCity = '';
    entityCountryName = '';
    reviewStatus = '';
    reviewRemark = '';
    statusTimestamp = '';
    statusFlag = '';
    showMore = false;
    selected = false;
    isCleansedAddress = false;
    checked = false;
    uniqId = '';
    rowStatusFlag = '';
    entityMatchId = '';
    onyxSystemEntityId = '';


    constructor(data: IEntityLookup = null) {
      if (data) {
        this.onyxEntityId = data.onyx_entity_id;
        this.onyxApplicationId = data.onyx_application_id;
        this.onyxApplicationName = data.onyx_application_name;
        this.onyxSubgroupId = data.onyx_subgroup_id;
        this.onyxGroupId = data.onyx_group_id;
        this.onyxSubgroupName = data.onyx_subgroup_name  || data.entity_subgroup_name;
        this.onyxGroupName =  data.onyx_group_name || data.entity_group_name;
        this.onyxSystemEntityId =  data.onyx_system_entity_id;
        this.onyxSystemSubgroupId =  data.onyx_system_subgroup_id;
        this.onyxSystemGroupId =  data.onyx_system_group_id;
        this.entityName =  data.onyx_entity_name;
        this.entityDisplayName = data.entity_display_name;
        this.entityAddress1 =  data.entity_address1;
        this.entityAddress2 = data.entity_address2;
        this.entityCity = data.entity_city;
        this.entityCountryName = '';
        this.reviewStatus = data.review_status;
        this.reviewRemark = data.review_remark;
        this.statusTimestamp = data.row_update_timestamp;
        this.statusFlag = '';
        this.isCleansedAddress = data.is_cleansed_address;
        this.rowStatusFlag = '';
        this.entityMatchId = data.entity_match_id;
        this.uniqId = Guid.newGuid();
      }
    }
}

export class EntityRequestModel implements IEntityLookup {
    id_origin?: string;
    entity_match_id?: string;
    onyx_system_entity_id?: string;
    onyx_system_subgroup_id?: string;
    onyx_system_group_id?: string;
    onyx_entity_id?: string;
    onyx_subgroup_id?: string;
    onyx_entity_name?: string;
    onyx_group_name?: string;
    onyx_subgroup_name?: string;
    onyx_group_id?: string;
    onyx_application_id?: string;
    onyx_application_name?: string;
    entity_lookup_display_name?: string;
    entity_display_name?: string;
    entity_address1?: string;
    entity_address2?: string;
    formatted_address?: string;
    entity_city?: string;
    entity_state?: string;
    entity_zipcode?: string;
    review_status?: string;
    review_remark?: string;
    review_remark_first_part?: string;
    review_remark_last_part?: string;
    row_update_timestamp?: string;
    is_cleansed_address?: boolean;
    entity_score?: number;
    added_lookup_list?: IEntityLookup[];

    constructor(data: IEntityMaster) {
        this.onyx_entity_id = data.onyxEntityId || '';
        this.onyx_application_id = data.onyxApplicationId || '';
        this.onyx_subgroup_id = data.onyxSubgroupId || '';
        this.onyx_group_id = data.onyxGroupId || '';
        this.onyx_subgroup_name = data.onyxSubgroupName || '';
        this.onyx_group_name = data.onyxGroupName || '';
        this.onyx_system_entity_id = data.onyxSystemEntityId || '';
        this.onyx_system_subgroup_id = data.onyxSystemSubgroupId || '';
        this.onyx_system_group_id = data.onyxSystemGroupId || '';
        this.onyx_entity_name = data.entityName || '';
        this.entity_display_name = data.entityDisplayName || '';
        this.entity_address1 = data.entityAddress1 || '';
        this.entity_address2 = data.entityAddress2 || '';
        this.entity_city = data.entityCity || '';
        this.review_status = data.reviewStatus || '';
        this.review_remark = data.reviewRemark || '';
        this.row_update_timestamp = data.statusTimestamp || '';
        this.is_cleansed_address = data.isCleansedAddress;
        this.entity_match_id = data.entityMatchId;
    }
}
