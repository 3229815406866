import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  private tokenWorker: Worker;

  constructor() {
    this.tokenWorker = new Worker('../workers/token.worker.ts', {type: 'module'});
  }

  public startTokenCounter(dataCounter) {
    this.tokenWorker.postMessage({type: 'startTokenCounter', data: dataCounter});
  }

  public stopTokenCounter() {
    this.tokenWorker.postMessage({type: 'stopTokenCounter'});
  }

  public startTimeCounter(tokenSeconds) {
    this.tokenWorker.postMessage({type: 'startTimeCounter', tokenSeconds});
  }
  public stopTimeCounter() {
    this.tokenWorker.postMessage({type: 'stopTokenCounter'});
  }

  public getCounterUpdate(callback: (counterInfo: any) => void) {
    this.tokenWorker.onmessage = (event) => {
      callback(event.data);
    };
  }
}
