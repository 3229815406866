import { PaginationServiceQueryModel } from '@app-shared/models/pagination-service';

export class NewEntityMasterSearchQueryModel extends PaginationServiceQueryModel {
    public id = '';
    public name = '';
    public onyx_entity_name = '';
    public onyx_entity_address = '';
    public page_number = 0;
    constructor(data = null) {
        super();
        if (data) {
            this.id = data.onyxEntityId;
            this.name = data.entityName;
            this.onyx_entity_name = data.entityName;
            this.onyx_entity_address = data.entityAddress;
        }
    }
}
