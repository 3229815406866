import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { OrmStatusReviewS } from '@app-shared/const/orm-pending-list';
import { IStatusCountStore } from '@app/shared/interfaces/status-count-store.interface';
import { StatusCountModel } from '@app-shared/models/status-counter.model';
import { ReviewStatusShow } from '@app/shared/models/filter-search';
import { Subscription } from 'rxjs';
import { ICountRefreshService } from '@app/shared/interfaces/status-count-refresh-service.interface';

@Component({
  selector: 'app-status-count',
  templateUrl: './status-count.component.html',
  styleUrls: ['./status-count.component.scss']
})
export class StatusCountComponent implements OnInit, OnChanges, OnDestroy {

  @Input() showStatus: ReviewStatusShow = new ReviewStatusShow({completed: true, rejected: true});
  @Input() statusCounter: IStatusCountStore = null;
  @Input() id: string = null;
  @Input() refreshListener: ICountRefreshService = null;

  @Output() statusCountListEvent = new EventEmitter<StatusCountModel>();

  public counter: StatusCountModel = null;
  public statusType = OrmStatusReviewS;
  public statusCountSub = new Subscription();

  constructor() { }

  ngOnDestroy(): void {
    this.statusCountSub.unsubscribe();
  }

  ngOnInit(): void {
    this.createSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusCounter?.currentValue) {
      this.getData();
    }
  }

  getData() {
    this.statusCounter.getCounter(this.id).subscribe(item => {
      this.counter = item;
      this.statusCountListEvent.emit(item);
    });
  }

  createSubscriptions(){
    if (this.refreshListener) {
      this.statusCountSub.add(
        this.refreshListener.getRefreshCountListObs().subscribe(item => {
          if (this.id === item.id) {
            this.getData();
          }
        })
      );
    }
  }

}
