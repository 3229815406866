export const TYPE_SEARCH_NEW_MASTER: Record<string, string> = {
    aSubgroup: 'approved subgroup',
    aGroup: 'approved group',
    entity: 'entity',
    pEntity: 'pending entity',
    pGroup: 'pending group',
    pSubgroup: 'pending subgroup',
    entityMaster: 'entity master',
};

export const MASTER_TYPE: Record<string, string> = {
    INDEP: 'INDEP',
    PROP: 'PROP'
};
