import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PM_CHANGE_MASTER_ALL_ACTIONS } from '@app/module-pending/const/entity/pending-matches-change-master-all.const';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EntityBulkCardListenerService } from '@app/shared/services/entity-bulk-card-listener.service';
import { IEntityMaster } from '@app/shared/interfaces/entity-master.interface';
import { IEntityBulkCardList } from '@app/shared/interfaces/entity-bulk-card-list';

@Component({
  selector: 'app-bulk-card-list',
  templateUrl: './bulk-card-list.component.html',
  styleUrls: ['./bulk-card-list.component.scss']
})
export class EntityBulkCardListComponent implements OnInit, OnDestroy {

  @Input() onyxEntityId: string = null;
  @Input() entityBulkCardListStore: IEntityBulkCardList = null;
  @Output() setSelectedRecords = new EventEmitter<IEntityMaster[]>();

  public lookupList: IEntityMaster[] = [];
  public selectedLookupList: IEntityMaster[] = [];
  public lookupCardListSubs = new Subscription();

  constructor(private _entityBulkCardListenerService: EntityBulkCardListenerService) { }

  ngOnInit(): void {
    this.createSubscriptions();
  }

  ngOnDestroy(): void {
    this.lookupCardListSubs.unsubscribe();
  }

  public selectAllPendingLookupRecords(event) {
    const statusChck = event.currentTarget.checked;
    this.lookupList.map(item => {
      item.checked = statusChck;
      if (statusChck) {
        this.selectedItem(item);
      } else {
        this.removedItem(item);
      }
    });
  }

  public selectedItem(item: IEntityMaster) {
    const indx = this.selectedLookupList.findIndex(att => att.onyxSystemEntityId === item.onyxSystemEntityId &&
      att.entityDisplayName === item.entityDisplayName &&
      att.entityAddress1 === item.entityAddress1 &&
      att.entityAddress2 === item.entityAddress2
    );
    const indxList = this.lookupList.findIndex(att => att.onyxSystemEntityId === item.onyxSystemEntityId &&
      att.entityDisplayName === item.entityDisplayName &&
      att.entityAddress1 === item.entityAddress1 &&
      att.entityAddress2 === item.entityAddress2
    );
    if (indx === -1) {
        this.selectedLookupList.push(item);
        this.setSelectedRecords.emit(this.selectedLookupList);
    }
    if (indxList > -1) {
      this.lookupList[indxList].checked = true;
    }
  }

  public removedItem(item: IEntityMaster) {
    const indx = this.selectedLookupList.findIndex(att => att.onyxSystemEntityId === item.onyxSystemEntityId &&
                                            att.entityDisplayName === item.entityDisplayName &&
                                            att.entityAddress1 === item.entityAddress1 &&
                                            att.entityAddress2 === item.entityAddress2
                                          );
    const indxList = this.lookupList.findIndex(att => att.onyxSystemEntityId === item.onyxSystemEntityId &&
                                      att.entityDisplayName === item.entityDisplayName &&
                                      att.entityAddress1 === item.entityAddress1 &&
                                      att.entityAddress2 === item.entityAddress2
                                    );
    if (indx > -1) {
      this.selectedLookupList.splice(indx, 1);
      this.setSelectedRecords.emit(this.selectedLookupList);
    }
    if (indxList > -1) {
      this.lookupList[indxList].checked = false;
    }
  }

  private createSubscriptions() {
    this.entityBulkCardListStore.getEntityRecordsBulk(this.onyxEntityId).pipe(take(1)).subscribe(list => {
      this.lookupList = list;
    });

    this.lookupCardListSubs.add(
      this._entityBulkCardListenerService.getRecordItem().subscribe(data => {
        if (data.action === PM_CHANGE_MASTER_ALL_ACTIONS.R) {
          this.removedItem(data.item);
        }
      })
    );
  }
}
