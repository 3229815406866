import { Guid } from '@app-shared/utils/guid';
import { IEntityApprovedMaster } from '../interfaces/entity-approved-master.interface';

export class EntityApprovedMasterItemModel implements IEntityApprovedMaster {
    onyxEntityId: string;
    onyxApplicationId: string;
    onyxApplicationName: string;
    onyxSubgroupId: string;
    onyxGroupId: string;
    onyxSubgroupName: string;
    onyxGroupName: string;
    onyxSystemSubgroupId: string;
    onyxSystemGroupId: string;
    entityName: string;
    entityDisplayName: string;
    entityAddress1: string;
    entityAddress2: string;
    entityCity: string;
    entityCountryName: string;
    reviewStatus: string;
    reviewRemark: string;
    statusTimestamp: string;
    statusFlag: string;
    showMore: boolean;
    selected: boolean;
    isCleansedAddress: boolean;
    checked: boolean;
    uniqId: string;
    rowStatusFlag: string;
    entityMatchId: string;
    onyxSystemEntityId: string;

    constructor(data = null) {
        if (data) {
            this.onyxEntityId = data.onyx_entity_id;
            this.onyxSubgroupId = data.onyx_subgroup_id;
            this.onyxGroupId = data.onyx_group_id;
            this.onyxSubgroupName = data.onyx_subgroup_name;
            this.onyxGroupName = data.onyx_group_name;
            this.onyxSystemSubgroupId = data.entity_system_sugroup_id;
            this.onyxSystemGroupId = data.entity_system_group_id;
            this.entityName = data.entity_name;
            this.entityDisplayName = data.entity_display_name;
            this.entityAddress1 = data.entity_address1;
            this.entityAddress2 = data.entity_address2;
            this.entityCity = data.entity_city;
            this.entityCountryName = data.entity_country_name;
            this.reviewStatus = data.review_status;
            this.rowStatusFlag = data.row_status_flag;
            this.reviewRemark = data.review_remark;
            this.statusTimestamp = data.review_timestamp;
            this.statusFlag = data.status_flag;
            this.onyxApplicationId = data.onyx_application_id;
            this.onyxApplicationName = data.onyx_application_name;
            this.entityMatchId = data.entity_match_id;
            this.isCleansedAddress = data.is_cleansed_address;
            this.onyxSystemEntityId = data.onyx_system_entity_id;
            this.uniqId = Guid.newGuid();
        }
    }
}
