import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccessDeniedComponent } from '@app-shared/components/access-denied/access-denied.component';
import { AlertToastComponent } from '@app-shared/components/alert-toast/alert-toast.component';
import { AlertConfirmationComponent } from './components/alert-confirmation/alert-confirmation.component';
import { RefreshTokenInterceptor } from '@app-auth/interceptors/refresh-token.interceptor';
import { SpinnerInterceptor } from '@onyx/core';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { OnyxIamSecurityDirective } from './directives/onyx-iam-service.directive';
import { OnyxIamNotInSecurityDirective } from './directives/onyx-iam-not-in-service.directive';
import { FilterSearchComponent } from './components-reference-master/filter-search/filter-search.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TableEmptyResultComponent } from './components/table-empty-result/table-empty-result.component';
import { StatusCountComponent } from './components-reference-master/status-count/status-count.component';
import { StatusTagComponent } from './components-reference-master/status-tag/status-tag.component';
import { ReviewRemarkComponent } from './components-reference-master/modal-review-remark/review-remark.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RemappingDetailDataComponent } from './components-reference-master/remapping-detail-data/remapping-detail-data.component';
import { CreateNewMasterComponent } from './components-reference-master/create-new-master/create-new-master.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HardDeleteComponent } from './components-reference-master/hard-delete/hard-delete.component';
import { BulkCardComponent } from './components-reference-master/bulk-card/bulk-card.component';
import { BulkCardListComponent } from './components-reference-master/bulk-card-list/bulk-card-list.component';
import { BulkCardRadioListComponent } from './components-reference-master/bulk-card-radio-list/bulk-card-radio-list.component';
import { ChangeMasterWizardComponent } from './components-reference-master/change-master-wizard/change-master-wizard.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { SearchBasicListRadioComponent } from './components-reference-master/search-basic-list-radio/search-basic-list-radio.component';
import { CardSelectedComponent } from './components-reference-master/card-selected/card-selected.component';
import { SearchBasicPanelComponent } from './components-reference-master/search-basic-panel/search-basic-panel.component';

@NgModule({
  declarations: [
    AlertToastComponent,
    AlertConfirmationComponent,
    AccessDeniedComponent,
    PageSpinnerComponent,
    PaginationComponent,
    OnyxIamSecurityDirective,
    OnyxIamNotInSecurityDirective,
    FilterSearchComponent,
    TableEmptyResultComponent,
    StatusCountComponent,
    StatusTagComponent,
    ReviewRemarkComponent,
    RemappingDetailDataComponent,
    CreateNewMasterComponent,
    HardDeleteComponent,
    BulkCardComponent,
    BulkCardListComponent,
    SearchBasicPanelComponent,
    BulkCardRadioListComponent,
    ChangeMasterWizardComponent,
    SearchBasicListRadioComponent,
    CardSelectedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    TooltipModule,
    TypeaheadModule,
    NgbModule,
    NgxDesignSystemComponentsModule,
    CollapseModule,
    ClipboardModule,
    MatStepperModule,
    MatIconModule
  ],
  exports: [
    AlertToastComponent,
    AlertConfirmationComponent,
    TranslateModule,
    TooltipModule,
    NgbModule,
    NgxDesignSystemComponentsModule,
    PageSpinnerComponent,
    PaginationComponent,
    OnyxIamSecurityDirective,
    OnyxIamNotInSecurityDirective,
    FilterSearchComponent,
    TableEmptyResultComponent,
    StatusCountComponent,
    StatusTagComponent,
    ReviewRemarkComponent,
    RemappingDetailDataComponent,
    CreateNewMasterComponent,
    ClipboardModule,
    HardDeleteComponent,
    BulkCardListComponent,
    BulkCardComponent,
    SearchBasicPanelComponent,
    BulkCardRadioListComponent,
    ChangeMasterWizardComponent,
    SearchBasicListRadioComponent,
    CardSelectedComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ]
})
export class SharedModule { }
