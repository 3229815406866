import { Component, Input, OnInit } from '@angular/core';
import { ICardDetail } from '@app/shared/interfaces/card-detail.interface';

@Component({
  selector: 'app-remapping-detail-data',
  templateUrl: './remapping-detail-data.component.html',
  styleUrls: ['./remapping-detail-data.component.scss']
})
export class RemappingDetailDataComponent implements OnInit {
  @Input() detailTitle: string;
  @Input() detail: ICardDetail[];

  public isEntityInfoCollapse = false;

  constructor() { }

  ngOnInit(): void {
  }

}
