export const mergeObjectsRecursively =  (objects: Record<string, unknown>[]): Record<string, unknown> => {
    const mergedObject: Record<string, unknown> = {};
    for (const obj of objects) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    const keyStr = mergedObject[key];
                    const objStr = obj[key];
                    mergedObject[key] = mergeObjectsRecursively([{keyStr, objStr}]);
                } else {
                mergedObject[key] = obj[key];
                }
            }
        }
    }
    return mergedObject;
};
