import * as defaultsDeep from 'lodash.defaultsdeep';
import { environmentDefaults } from './environment-defaults';

export const override = {
  name: 'prod',
  production: true,
  serviceUrl: 'https://orm.api.onyxcentersource.com',
  portalUrl: 'https://portal.onyxcentersource.com',
  securityUrl: 'https://security.service.onyxcentersource.com',
  requestTimeout: 300000,
  requireDateRange: true
};

export const environment: any = defaultsDeep(override, environmentDefaults);
