import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-confirmation',
  templateUrl: './alert-confirmation.component.html',
  styleUrls: ['./alert-confirmation.component.scss'],
  providers: [BsModalService]
})
export class AlertConfirmationComponent implements OnInit, OnDestroy {

  @Output() closedModal = new EventEmitter<string>();
  public title;
  public titleTranslation;
  public message;
  public titleHtml;
  public messageHtml;
  public actionOk = 'APP.BUTTON.ACCEPT';
  public actionCancel = 'APP.BUTTON.CANCEL';
  public showOkButton = true;
  public showCancelButton = true;

  constructor(private modalService: BsModalService) { }

  ngOnDestroy(): void {
    this.close('deleted');
  }

  ngOnInit(): void {}

  public close(action) {
    this.closedModal.emit(action);
    this.modalService.hide();
  }
}
