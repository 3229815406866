
export class UserPermission {
    token: string;
    error: string = null;
    permissions: string[];

    constructor(data = null){
      if (data != null) {
        this.token = data.token;
        this.permissions = data.permissions;
      }
    }
  }
