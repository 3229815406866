export class PaginationServiceModel {
    id ? = '';
    totalElementsPerPage = 10;
    pageNumber = 0;
    constructor(data = null){
        if (data) {
            this.id = data.id;
        }
    }
}

export class PaginationServiceQueryModel {
    total_elements_per_page = 10;
    page_number = 0;

    constructor(data = null) {
        if (data) {
            this.total_elements_per_page = data.totalElementsPerPage;
            this.page_number = data.pageNumber;
        }
    }
}
