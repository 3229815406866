import { Injectable } from '@angular/core';
import { PendingMatchesMasterModel } from 'src/app/module-pending/components/entity/model/pending-matches-master';
import { PendingLookupRecordItemType } from 'src/app/module-pending/components/entity/types/pending-matches-change-master-all.type';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityBulkCardListenerService {

  public entiRecordListSub = new Subject<PendingMatchesMasterModel[]>();
  public entiRecordItemSub = new Subject<PendingLookupRecordItemType>();

  constructor() { }

  public setRecordList(item: PendingMatchesMasterModel[]) {
    this.entiRecordListSub.next(item);
  }

  public getRecordList() {
    return this.entiRecordListSub.asObservable();
  }

  public setRecordItem(data: PendingLookupRecordItemType) {
    this.entiRecordItemSub.next(data);
  }

  public getRecordItem() {
    return this.entiRecordItemSub.asObservable();
  }
}
