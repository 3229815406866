<div class="card-deck" *ngIf="item">
    <div class="card" [ngClass]="{'is-selected': isSelected}">
        <div class="card-header p-2">
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="card-body p-2">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
</div>
