<div class="card mb-2" *ngIf="item">
    <div class="row mx-0">
        <div class="col-12 py-2">           
            <div class="custom-control custom-checkbox d-inline-block mb-0">
                <input type="checkbox" 
                    class="custom-control-input c-pointer" 
                    [id]="item.uniqId" 
                    [checked]="item.checked"
                    (click)="selectLookupRecord($event, item)">
                <label class="custom-control-label c-pointer" [for]="item.uniqId">
                    <ng-container>
                        <b class="mr-1">{{'APP.PENDING_MATCHES.COMPARISON_LIST.HEADER_SYSTEM_INFO.ENTITY_ID' | translate}}</b>
                        <span class="mr-2">{{item.onyxSystemEntityId}}</span>
                    </ng-container>
                    <ng-container>
                        <b class="mr-1">{{'APP.PENDING_MATCHES.COMPARISON_LIST.HEADER_DISPLAY_NAME' | translate}}</b>
                        <span class="mr-2">{{item.entityDisplayName}}</span>
                    </ng-container>
                    <ng-container>
                        <b class="mr-1">{{'APP.PENDING_MATCHES.COMPARISON_LIST.HEADER_CLEANSED_ADDRESS_LINE' | translate}}</b>
                        <span class="mr-2">{{item.entityAddress1 || '-'}}, {{item.entityAddress2 || '-'}}</span>
                    </ng-container> 
                </label>                     
            </div>
        </div>
    </div>
</div>