import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AlertConfirmationComponent } from '@app/shared/components/alert-confirmation/alert-confirmation.component';
import { IEntityNewSearchStore } from '@app/shared/interfaces/entity-new-search.interface';
import { EntityModel } from '@app/shared/models/entity.model';
import { NewSearchListenerService } from '@app/shared/services/new-search-listener/new-search-listener.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ISearchBasic } from '@app/shared/interfaces/search-basic.interface';
import { SearchBasicModel } from '@app/shared/models/search-basic.model';

@Component({
  selector: 'app-search-basic-list-radio',
  templateUrl: './search-basic-list-radio.component.html',
  styleUrls: ['./search-basic-list-radio.component.scss'],
  providers: [BsModalService]
})
export class SearchBasicListRadioComponent implements OnInit {
  @Output() modalIdEv = new EventEmitter<number>();
  @Input() searchEntityMasterStore: IEntityNewSearchStore = null;
  @Input() searchType: string = null;
  @Input() additionalParams: Record<string, string> = null;
  @Input() itemDetailTemplate: TemplateRef<any>;
  @Input() skipRestrictionsFilters = false;

  public masterListSearched: EntityModel[] = null;
  public searchMasterPagination: SearchBasicModel = null;
  public totalSearchData = 0;
  public newEntity: EntityModel = null;

  public isCollapse = {
    search: false,
    list: false
  };

  public translateMessages = {
    titleEditGroupId: 'APP.SEARCH.CHANGE_TITLE',
    messageEditGroupId: 'APP.SEARCH.CHANGE_DESCRIPTION',
    actionOkCode: 'APP.BUTTON.ACCEPT',
    actionCancelCode: 'APP.BUTTON.CANCEL',
    titleEditGroupIdMessage: '' ,
    messageEditGroupIdMessage: '',
    actionOk: '',
    actionCancel: ''
  };

  constructor( private _modalService: BsModalService,
               private _translateService: TranslateService,
               private _newSearchListenerService: NewSearchListenerService
            ) { }

  ngOnInit(): void {
    this.createTranslations();
  }

  public createTranslations() {
    const params = {type: this.searchType};
    this.translateMessages.titleEditGroupIdMessage = this._translateService.instant(this.translateMessages.titleEditGroupId, params);
    this.translateMessages.messageEditGroupIdMessage  = this._translateService.instant(this.translateMessages.messageEditGroupId, params);

    this._translateService.get(this.translateMessages.actionOkCode)
    .subscribe(p => { this.translateMessages.actionOk = p; });

    this._translateService.get(this.translateMessages.actionCancelCode)
    .subscribe(p => { this.translateMessages.actionCancel = p; });
  }

  public searchNewMaster(params: ISearchBasic){
    this.masterListSearched = null;
    this.searchMasterPagination = new SearchBasicModel(params);
    this.isCollapse.search = true;
    if (this.additionalParams) {
      this.searchMasterPagination = {...this.searchMasterPagination, ...this.additionalParams};
    }
    this.searchNewMasterRequest(this.searchMasterPagination);
  }

  public searchNewMasterRequest(params: ISearchBasic) {
    if (this.searchEntityMasterStore) {
      this.searchEntityMasterStore.getEntitySearch(params).subscribe(res => {
        if (res.data.length > 0) {
          this.totalSearchData = res.count;
          this.masterListSearched = this.masterListSearched ? this.masterListSearched.concat(res.data) : res.data;
        } else {
          this.totalSearchData = 0;
          this.masterListSearched = [];
        }
      });
    }
  }

  public setMoreNewMasterRecords() {
    this.searchMasterPagination.page_number++;
    this.searchNewMasterRequest(this.searchMasterPagination);
  }

  public setSelectedMaster(item: EntityModel) {
    if (item) {
      const configModalDelete = {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-md',
          initialState: {
            title: this.translateMessages.titleEditGroupIdMessage,
            message: this.translateMessages.messageEditGroupIdMessage,
            actionOk: this.translateMessages.actionOk,
            actionCancel: this.translateMessages.actionCancel
          }
        };

      const _bsModalRef = this._modalService.show(AlertConfirmationComponent, configModalDelete );

      _bsModalRef.content.closedModal.subscribe((value) => {
        if (value === 'ok') {
          this.newEntity = item;
          this.isCollapse.search = true;
          this._newSearchListenerService.setItemSelection(this.searchType, item);
        } else if (value === 'cancel') {
          this._newSearchListenerService.setClearSelection();
        }
        setTimeout(() => {
          _bsModalRef.hide();
        }, 0);
      });
    }
  }
}
