import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CoreModule, JWT_TOKEN_STORAGE_KEY } from '@onyx/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from './shared/shared.module';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { CommonModule } from '@angular/common';
import { AlertToastService } from '@app-shared/services/alert-toast.service';
import { JwtTokenManagementStore } from './module-authorization/services/jwt-token-management.store';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { GpSpinnerService } from '@app-shared/services/gp-spinner.service';
import { MultiTranslateHttpLoader } from '@app-shared/utils/multi-translate-http-loader.utils';

export function tokenGetter() {
  return localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
}
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, {
    resources: [
      { prefix: './assets/i18n/en/orm', suffix: '.json' },
      { prefix: './assets/i18n/en/group-pay', suffix: '.json' },
      { prefix: './assets/i18n/en/pending-entity', suffix: '.json' },
      { prefix: './assets/i18n/en/pending-entity-group', suffix: '.json' },
      { prefix: './assets/i18n/en/pending-entity-subgroup', suffix: '.json' },
      { prefix: './assets/i18n/en/pending-agency', suffix: '.json' },
      { prefix: './assets/i18n/en/approved-entity', suffix: '.json' },
    ]
  });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    CoreModule.forRoot(environment),

  ],
  providers: [
    TranslateService,
    JwtTokenManagementStore,
    AlertToastService,
    GpSpinnerService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
