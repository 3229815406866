import { ISearchBasic } from '../interfaces/search-basic.interface';
import { ISearchMainTable } from '../interfaces/search-main-table.interface';

export class SearchBasicModel implements ISearchMainTable, ISearchBasic{
    id = '';
    total_elements_per_page = 0;
    page_number = 0;
    name = '';
    address = '';

    constructor(data: ISearchBasic = null) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.address = data.address;
            this.page_number = 0;
            this.total_elements_per_page = 10;
        }
    }

}
