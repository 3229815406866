import { DropDownList } from '@app-shared/models/dropdown.model';
import { OrmApprovedStatusReview } from './orm-approved-status-list';

export class OrmStatusReviewS {
    public static readonly PENDING = 'P';
    public static readonly APPROVED = 'A';
    public static readonly ADDED = 'AD';
    public static readonly WORKING_IN_PROGRESS = 'W';
    public static readonly COMPLETED = 'C';
    public static readonly REJECTED = 'R';
    public static readonly OTHER = '';
    public static readonly DELETED = 'D';
    public static readonly UPDATED = 'U';
}

export class OrmStatusReview {
    public static readonly PENDING = 'Pending';
    public static readonly APPROVED = 'Approved';
    public static readonly WORKING_IN_PROGRESS = 'Work in progress';
    public static readonly COMPLETED = 'Completed';
    public static readonly REJECTED = 'Rejected';
    public static readonly BUSINESS_REVIEW = 'Business Review Awaiting';
    public static readonly EXCEPTION = 'Exception';
}

export class OrmStatusReviewDeleteS {
    public static readonly DELETE = 'D';
    public static readonly HARD_DELETE = 'HD';
}

export class OrmOrigen {
    public static readonly PENDING = 'Pending';
    public static readonly LOOKUP = 'Lookup';
    public static readonly OTHER_POSSIBLE = 'Other Possible';
}

export const COMPARISON_RECORD_TYPE = {
    P: OrmOrigen.PENDING,
    L: OrmOrigen.LOOKUP,
    OP: OrmOrigen.OTHER_POSSIBLE,
};

export const entityPendingStatus = () => {
    const statusList: DropDownList[] = [];
    statusList.push({text: 'Pending', value: 'P'});
    statusList.push({text: 'Work in progress', value: 'W'});
    statusList.push({text: 'Approved', value: 'A'});
    statusList.push({text: 'Rejected', value: 'R'});
    statusList.push({text: 'Exception', value: 'E'});
    statusList.push({text: 'Business Review Awaiting', value: 'B'});
    statusList.push({text: 'Other', value: ''});
    return statusList;
};


export const agencyPendingStatus = () => {
    const statusList: DropDownList[] = [];
    statusList.push({text: 'Pending', value: 'P'});
    statusList.push({text: 'Work in progress', value: 'W'});
    statusList.push({text: 'Approved', value: 'A'});
    return statusList;
};

export const groupPendingStatus = () => {
    const statusList: DropDownList[] = [];
    statusList.push({text: 'Pending', value: 'P'});
    statusList.push({text: 'Work in progress', value: 'W'});
    statusList.push({text: 'Approved', value: 'A'});
    statusList.push({text: 'Rejected', value: 'R'});
    return statusList;
};

export const entityMasterDeleteStatus = () => {
    const statusList: DropDownList[] = [];
    statusList.push({text: 'Deleted', value: 'D'});
    return statusList;
};

export const entityApprovedStatus = () => {
    const statusList: DropDownList[] = [];
    statusList.push({text: OrmApprovedStatusReview.ADDED_S, value: OrmApprovedStatusReview.ADDED_S});
    statusList.push({text: OrmApprovedStatusReview.DELETED_S, value: OrmApprovedStatusReview.DELETED_S});
    return statusList;
};

export class EntitySatusFlag {
    public static readonly MASTER = 'Master';
    public static readonly PENDING = 'Pending';
}

export const STATUS_REVIEW = {
    P: OrmStatusReview.PENDING,
    A: OrmStatusReview.APPROVED,
    W: OrmStatusReview.WORKING_IN_PROGRESS,
    C: OrmStatusReview.COMPLETED,
    R: OrmStatusReview.REJECTED,
};

export const STATUS_REVIEW_WR = {
    P: OrmStatusReview.PENDING,
    A: OrmStatusReview.APPROVED,
    W: OrmStatusReview.WORKING_IN_PROGRESS,
    C: OrmStatusReview.COMPLETED,
    B: OrmStatusReview.BUSINESS_REVIEW,
    E: OrmStatusReview.EXCEPTION
};
