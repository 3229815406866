import { Injectable } from '@angular/core';
import { SpinnerService } from '@onyx/core';

@Injectable({
  providedIn: 'root'
})
export class GpSpinnerService {

  constructor(private spinnerService: SpinnerService) { }

  public isLoading() {
    return this.spinnerService.isLoading;
  }

  public message() {
    return this.spinnerService.message;
  }
  public show() {
    this.spinnerService.show();
  }
  public hide(){
    this.spinnerService.hide();
  }
  public setMessage(msg: string) {
    this.spinnerService.setMessage(msg);
  }
}
