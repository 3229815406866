import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PendingMatchesMasterModel } from 'src/app/module-pending/components/entity/model/pending-matches-master';
import { EditPendingMatchService } from 'src/app/module-pending/components/entity/services/edit-pending-match/edit-pending-match.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entity-bulk-card',
  templateUrl: './entity-bulk-card.component.html',
  styleUrls: ['./entity-bulk-card.component.scss']
})
export class EntityBulkCardComponent implements OnInit, OnDestroy {
  @Input() item: PendingMatchesMasterModel = null;
  @Output() selectedItem = new EventEmitter<PendingMatchesMasterModel>();
  @Output() removedItem = new EventEmitter<PendingMatchesMasterModel>();

  public showMore = false;
  public itemChecked = false;
  public pendingMatchesEntityCardSubs = new Subscription();

  constructor() {

    this.createSubscriptions();
  }

  ngOnDestroy(): void {
    this.pendingMatchesEntityCardSubs.unsubscribe();
  }

  ngOnInit(): void {}

  public selectLookupRecord(event, item: PendingMatchesMasterModel) {
    const statusChck = event.currentTarget.checked;
    if (statusChck) {
      this.selectedItem.emit(item);
    } else {
      this.removedItem.emit(item);
    }
  }

  private createSubscriptions() {
   // this.pendingMatchesEntityCardSubs.add(
     /* this.editPendingMatchService.geItemSeletectedObs().subscribe(itemSelected => {
        this.itemChecked = itemSelected && itemSelected.uniqId === this.item.uniqId ? true : null;
      })*/
   // );
  }
}
