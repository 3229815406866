import { Component, OnInit } from '@angular/core';
import { WorkerService } from '@app-auth/services/workers.service';
import { environment } from '@app-env/environment';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  public urlBack: string = environment.portalUrl;
  public contactEmail = '';
  public contact: string;

  constructor(private translateService: TranslateService, private workerService: WorkerService) {
  }

  ngOnInit() {
    const urlObj = { urlBack: this.urlBack, contactEmail: this.contactEmail};
    this.workerService.stopTimeCounter();
    this.translateService.get('app.accessDenied.contact', urlObj)
      .pipe(take(1))
      .subscribe((text: string) => {
        this.contact = text;
    });
  }

}
