import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app-env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActionPermissionsModel } from '@app-auth/model/action-permissions';
import { APP_PERMISSIONS, PERMISSIONS, REFRESH_TOKEN } from '@app-auth/const/authorization-endpoint.const';
import { JwtTokenManagementStub } from './jwt-token-management.stub';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenManagementService {
  private tokenUrl = `${environment.securityUrl}`;
  private appUrl = `${environment.serviceUrl}`;

  constructor(private httpClient: HttpClient) { }

  getUserPermissions(token): Observable<ActionPermissionsModel>{
   /* const response = JwtTokenManagementStub.getServerPermissionResponse();
    return of(response);
  */
    const data = {
      Authorization: token
    };
    return this.httpClient.post(`${this.appUrl}${APP_PERMISSIONS}`, data).pipe(
      catchError(err => {
        return throwError(err);
      }),
      map(response => new ActionPermissionsModel(response)));
  }

  refreshSession(token: string): Observable<string> {
    const data = {
      Authorization: token
    };
    return this.httpClient.post(`${this.tokenUrl}${REFRESH_TOKEN}/${environment.appName}`, data).pipe(
      catchError(err => {
        return throwError(err);
      }),
      map(newToken => newToken.toString() || ''));
  }
}
