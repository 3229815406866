import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { IMasterRecordModel } from '@app/shared/interfaces/master-record-model.interface';
import { EntityModel } from '@app/shared/models/entity.model';
import { NewSearchListenerService } from '@app/shared/services/new-search-listener/new-search-listener.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bulk-card-radio-list',
  templateUrl: './bulk-card-radio-list.component.html',
  styleUrls: ['./bulk-card-radio-list.component.scss']
})
export class BulkCardRadioListComponent implements OnInit, OnDestroy{
  @Input() itemDetailTemplate: TemplateRef<any>;

  @Input() lookupList: IMasterRecordModel[] = [];
  @Input() resultTotal: IMasterRecordModel[] = [];
  @Input() cardName = '';
  @Input() heightClass = 'medium';
  @Input() lookupCount = 0;
  @Input() isExternalDetail = false;
  @Input() showInfoName = false;

  @Output() setSelectedRecords = new EventEmitter<IMasterRecordModel>();
  @Output() setMoreRecords = new EventEmitter<void>();

  public actualSeletectRecord = null;
  public selectedLookupList: EntityModel[] = [];
  public bulkCardRadioListSubs = new Subscription();

  constructor(private _newSearchListenerService: NewSearchListenerService) { }


  ngOnInit(): void {
    this.crearSubscriptions();
  }

  ngOnDestroy(): void {
    this.bulkCardRadioListSubs.unsubscribe();
  }

  public crearSubscriptions() {
    this.bulkCardRadioListSubs.add(
      this._newSearchListenerService.getClearSelection().subscribe(() => {
        this.actualSeletectRecord = null;
      })
    );
  }

  public selectAllPendingLookupRecords(event) {
    const statusChck = event.currentTarget.checked;
    this.lookupList.map(item => {
      item.checked = statusChck;
      if (statusChck) {
        this.selectedItem(item);
      }
    });
  }

  public selectedItem(item: IMasterRecordModel) {
    this.setSelectedRecords.emit(item);
    this.actualSeletectRecord = item.uniqId;
  }

  public showMoreRecords() {
    this.setMoreRecords.emit();
  }
}
