import { ISearchMainTable } from '@app/shared/interfaces/search-main-table.interface';
import { ISearchMainTableModel } from '../interfaces/search-main-table-model.interface';

export class SearchMainTableModel implements ISearchMainTableModel {
  id = '';
  totalElementsPerPage = 10;
  pageNumber = 0;
  parentId = '';

  constructor(data: ISearchMainTable = null)
  {
    if (data != null) {
      this.id = data.id;
      this.totalElementsPerPage = data.total_elements_per_page || 10;
      this.pageNumber = data.page_number || 0;
      this.parentId = data.parent_id ?? '';
    }
  }

}


export class SearchMainTableQueryModel implements ISearchMainTable {
  id: string;
  total_elements_per_page = 10;
  page_number = 0;
  parent_id = '';

  constructor(data: ISearchMainTableModel = null)
  {
    if (data != null) {
      this.id = data.id;
      this.total_elements_per_page = data.totalElementsPerPage || 10;
      this.page_number = data.pageNumber || 0;
      this.parent_id = data.parentId || '';
    }
  }
}


