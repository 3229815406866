<div class="container-layout">
    <div class="container-navbar-top">
      <div class="row">
        <div class="col py-3">
          <!-- Hide the title unless specified in the design -->
        </div>
        <div class="col py-2 text-center">
          <a href="{{urlBack}}" class="nav-logo-link">
            <img src="assets/logo-inline-text-light.svg" alt="Onyx CenterSource" class="img-fluid nav-logo" />
          </a>
        </div>
        <div class="col py-3 text-right">
          <!-- Hide the user menu unless the user is authenticated and no security risks exist -->
        </div>
      </div>
    </div>
  
    <div class="container-layout h-fixed-500">
      <div class="container-fluid container-content">
        <div class="marquee marquee-danger">
          <i class="far fa-lock-alt marquee-icon"></i>
          <h1 class="marquee-heading">{{ 'APP.ACCESS_DENIED.TITLE' | translate }}</h1>
          <h2 class="marquee-message">{{ 'APP.ACCESS_DENIED.MESSAGE' | translate }}</h2>
          <p class="marquee-secondary-action" [innerHTML]="'APP.ACCESS_DENIED.CONTACT' | translate: {urlBack: urlBack}">
            
          </p>
        </div>
      </div>
    </div>
  </div>
  