<div class="modal-header">
    <h5 class="modal-title" id="contactDeleteModalLabel">{{'APP.MASTER.HARD_DELETE.TITLE' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close('cancel')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{'APP.MASTER.HARD_DELETE.DESCRIPTION'  | translate}}</p>
    <div>
        <label>{{'APP.MASTER.HARD_DELETE.CONFIRM_ID' | translate: {masterId: masterId} }}</label>
        <input type="text" class="form-control text-uppercase" [(ngModel)]="confirmMasterId">
    </div>   
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close('cancel')">{{'APP.BUTTON.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close('ok')" [disabled]="confirmMasterId?.toUpperCase() !== masterId?.toUpperCase()">{{'APP.BUTTON.ACCEPT' | translate}}</button>
</div>