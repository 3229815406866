import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { EntityApprovedMasterItemModel } from '@app/module-approved/components/entity/model/entity-approved-entity-master.model';
import { IEntityBulkCardList } from '@app/shared/interfaces/entity-bulk-card-list';
import { IEntityLookupRecord } from '@app/shared/interfaces/entity-lookup-record.interface';
import { IEntityMaster } from '@app/shared/interfaces/entity-master.interface';
import { IEntityNewSearchStore } from '@app/shared/interfaces/entity-new-search.interface';
import { EntityModel } from '@app/shared/models/entity.model';
import { NewEntityMasterSearchQueryModel } from '@app/shared/models/new-entity-master-search.model';

@Component({
  selector: 'app-change-master-wizard',
  templateUrl: './change-master-wizard.component.html',
  styleUrls: ['./change-master-wizard.component.scss']
})
export class EntityChangeMasterWizardComponent implements OnInit {

  @Input() isAbleStep1 = true;
  @Input() isAbleStep2 = true;
  @Input() isAbleStep3 = true;
  @Input() lookupStore: IEntityBulkCardList = null;
  @Input() searchEntityMasterStore: IEntityNewSearchStore = null;
  @Input() actualEntity: EntityApprovedMasterItemModel = null;

  @ViewChild('stepper') stepperWizard: MatStepper;

  public newEntity: EntityModel = null;
  public lookupList: IEntityMaster[] = [];
  public masterListSearched: EntityModel[] = null;
  public isLastStep = false;
  public totalSearchData = 0;
  public searchMasterPagination = null;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public stepStatus: {one: number, two: number} = {
    one: -1,
    two: -1
  };
  public isCollapse = {
    search: false,
    list: false
  };
  public validationMessage = {
    pendingLookupMissing: 'You must select pending records',
    searchNewMaster: 'You must select a new master'
  };

  constructor(
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForms();
  }

  private createForms() {
    const firstValidations = this.isAbleStep1 ? [Validators.required] : [];
    const secondValidations = this.isAbleStep2 ? [Validators.required] : [];
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', firstValidations]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', secondValidations]
    });
  }


  /**
   * Step: Click event on step number
   * @param stepper
   */
  public selectionChange(stepper: StepperSelectionEvent) {
    if (stepper.previouslySelectedIndex === 0) { // Go from step 1
      if (this.isAbleStep1) {
        this.validateStepOne();
      } else {
        this.validateStepTwo();
      }
    } else if (stepper.previouslySelectedIndex === 1) { // Go from step 2
      this.validateStepTwo();
    }
  }

  /**
   * Step: Function validation before step 2
   * @returns
   */
  public validateStepOne() {
    const oneValidated = this.lookupList.length > 0;
    this.stepStatus.one = oneValidated ? 1 : 0;
    return oneValidated;
  }

  /**
   * Step: Function validation before step 2
   * @returns
   */
  public validateStepTwo() {
    const twoValidated = this.newEntity !== null;
    this.stepStatus.two = twoValidated ? 1 : 0;
    return twoValidated;
  }

  /**
   * Step: Flag validation step 1
   */
  private setValuesStepOne() {
    const oneValidated = this.lookupList.length > 0;
    if (oneValidated) {
      this.firstFormGroup.get('firstCtrl').setValue('selected');
    }
    else {
      this.firstFormGroup.get('firstCtrl').setValue('');
    }
  }

 /**
  * Step: Flag validation step 2
  */
  private setValuesStepTwo() {
    const oneValidated = this.newEntity !== null;
    if (oneValidated) {
      this.secondFormGroup.get('secondCtrl').setValue('selected');
    }
    else {
      this.secondFormGroup.get('secondCtrl').setValue('');
    }
  }

  /**
   * Step: Click event stepper controller back
   * @param stepper
   */
  public goBack(stepper: MatStepper) {
    stepper.previous();
  }

  /**
   * Step: Click event stepper controller next
   * @param stepper
   */
  public goForward(stepper: MatStepper){
    if (stepper) {
      let isValidNextStep = false;
      this.isLastStep = false;
      if (stepper.selectedIndex === 0) { // Go to step 1
        isValidNextStep = this.isAbleStep1 ? this.validateStepOne() : this.validateStepTwo();
      } else if (stepper.selectedIndex === 1) { // Go to step 2
        isValidNextStep = this.validateStepTwo();
        this.isLastStep = isValidNextStep;
      }
      if (!this.isAbleStep1 && stepper.selectedIndex === 0) {
        this.isLastStep = isValidNextStep;
      }
      if (isValidNextStep) {
        stepper.next();
      }
    }
  }

  public setSelectedRecords(itemList){
    this.lookupList = itemList;
    this.setValuesStepOne();
  }

  public setSelectedMaster(item: EntityModel) {
    this.newEntity = item;
    this.isCollapse.list = true;
    this.isCollapse.search = true;
    this.setValuesStepTwo();
  }

  public searchNewMaster(params: IEntityLookupRecord){
    const data = new EntityModel(params);
    this.masterListSearched = null;
    this.searchMasterPagination = new NewEntityMasterSearchQueryModel(data);
    this.searchNewMasterRequest(this.searchMasterPagination);
  }

  public searchNewMasterRequest(params: NewEntityMasterSearchQueryModel) {
    if (this.searchEntityMasterStore) {
      this.searchEntityMasterStore.getEntitySearch(params).subscribe(res => {
        if (res.data.length > 0) {
          this.totalSearchData = res.count;
          this.masterListSearched = this.masterListSearched ? this.masterListSearched.concat(res.data) : res.data;
        } else {
          this.totalSearchData = 0;
          this.masterListSearched = [];
        }
      });
    }
  }

  public setMoreNewMasterRecords() {
    this.searchMasterPagination.page_number++;
    this.searchNewMasterRequest(this.searchMasterPagination);
  }

  public get stepperChangeMasterWizard() {return this.stepperWizard; }

  public get selectedIndex() {
    if (!this.stepperWizard) {
      return 0;
    }
    return  this.stepperWizard.selectedIndex || 0;
  }

  public get newChangedEntity() {return this.newEntity; }

  public get selectedLookupList() {return this.lookupList; }

  public get isWizardLastStep() {return this.isLastStep; }
}
