import { IEntityMaster } from 'src/app/module-pending/components/entity/interfaces/entity-master.interface';
import { Guid } from '@app-shared/utils/guid';

export class PendingMatchesMasterListModel {
    data: PendingMatchesMasterModel[] = [];
    count: number;

    constructor(data = null){
        if (data) {
            this.count = data.count;
            for (const iterator of data.data) {
                this.data.push(new PendingMatchesMasterModel(iterator));
            }
        }
    }
}

export class PendingMatchesMasterModel implements IEntityMaster {
    onyxEntityId: string;
    onyxApplicationId: string;
    onyxSubgroupId: string;
    onyxGroupId: string;
    onyxSubgroupName: string;
    onyxGroupName: string;
    onyxSystemSubgroupId: string;
    onyxSystemGroupId: string;
    entityName: string;
    entityDisplayName: string;
    entityAddress1: string;
    entityAddress2: string;
    entityCity: string;
    entityCountryName: string;
    reviewStatus: string;
    reviewRemark: string;
    statusTimestamp: string;
    statusFlag: string;
    showMore: boolean;
    selected: boolean;
    isCleansedAddress: boolean;
    checked: boolean;
    uniqId: string;
    rowStatusFlag: string;
    entityMatchId: string;
    onyxSystemEntityId: string;

    constructor(data = null) {
        if (data) {
            this.onyxEntityId = data.onyx_entity_id;
            this.onyxSubgroupId = data.onyx_subgroup_id;
            this.onyxGroupId = data.onyx_group_id;
            this.onyxSubgroupName = data.entity_subgroup_display_name;
            this.onyxGroupName = data.entity_group_display_name;
            this.onyxSystemSubgroupId = data.entity_system_sugroup_id;
            this.onyxSystemGroupId = data.entity_system_group_id;
            this.entityName = data.entity_name;
            this.entityDisplayName = data.entity_display_name;
            this.entityAddress1 = data.entity_address1;
            this.entityAddress2 = data.entity_address2;
            this.entityCity = data.entity_city;
            this.entityCountryName = data.entity_country_name;
            this.reviewStatus = data.review_status;
            this.rowStatusFlag = data.row_status_flag;
            this.reviewRemark = data.review_remark;
            this.statusTimestamp = data.review_timestamp;
            this.statusFlag = data.status_flag;
            this.onyxApplicationId = data.onyx_application_id;
            this.entityMatchId = data.entity_match_id;
            this.isCleansedAddress = data.is_cleansed_address;
            this.onyxSystemEntityId = data.onyx_system_entity_id;
            this.uniqId = Guid.newGuid();
        }
    }
}

export class PendingMatchesMasterQueryModel {
    onyx_entity_id: string;
    onyx_subgroup_id: string;
    onyx_group_id: string;
    onyx_system_entity_id: string;
    onyx_system_subgroup_id: string;
    onyx_system_group_id: string;
    onyx_application_id: string;
    entity_subgroup_display_name: string;
    entity_group_display_name: string;
    entity_name: string;
    entity_display_name: string;
    entity_address1: string;
    entity_address2: string;
    entity_city: string;
    entity_country_name: string;
    row_status_flag: string;
    review_status: string;
    review_timestamp: string;
    status_flag: string;
    entity_match_id: string;
    review_note: string;

    constructor(data: IEntityMaster = null) {
        if (data) {
            this.onyx_entity_id = data.onyxEntityId;
            this.onyx_application_id = data.onyxApplicationId;
            this.onyx_subgroup_id = data.onyxSubgroupId;
            this.onyx_group_id = data.onyxGroupId;
            this.onyx_system_entity_id = data.onyxSystemEntityId;
            this.onyx_system_subgroup_id = data.onyxSystemSubgroupId;
            this.onyx_system_group_id = data.onyxSystemGroupId;
            this.review_status = data.reviewStatus;
            this.entity_match_id = data.entityMatchId;
            this.entity_address1 = data.entityAddress1;
            this.entity_address2 = data.entityAddress2;
            this.entity_display_name = data.entityDisplayName;
        }
    }
}
