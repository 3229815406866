import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TYPE_SEARCH_NEW_MASTER } from '@app/shared/const/create-new-master.const';
import { IEntityLookupRecord } from '@app/shared/interfaces/entity-lookup-record.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-panel-new-entity-search',
  templateUrl: './panel-new-entity-search.component.html',
  styleUrls: ['./panel-new-entity-search.component.scss']
})
export class EntityPanelNewEntitySearchComponent implements OnInit {
  @Output() cleanSearch = new EventEmitter<void>();
  @Output() search = new EventEmitter<IEntityLookupRecord>();
  @Input() searchType = TYPE_SEARCH_NEW_MASTER.entity;
  @Input() skipRestrictionsFilters = false;

  public searchFilterForm: FormGroup = new FormGroup({});
  public pendingMatchesSubs = new Subscription();
  public entitySearched = {
    id: null,
    matchCount: null
  };

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.createFilterForm();
  }

  public searchNewEntity() {
    const newEntity: IEntityLookupRecord = {
      id: this.searchFilterForm.get('onyx_entity_id').value,
      name: this.searchFilterForm.get('onyx_entity_name').value,
      onyx_entity_id: this.searchFilterForm.get('onyx_entity_id').value,
      onyx_entity_name: this.searchFilterForm.get('onyx_entity_name').value,
      entity_address: this.searchFilterForm.get('onyx_entity_address').value,
    };
    this.cleanSearch.emit();

    if (newEntity) {
      this.entitySearched.id = newEntity.onyx_entity_id;
      this.search.emit(newEntity);
    }
  }

  public isSearcherDisabled() {
    let statusDisabled = false;
    if (!this.skipRestrictionsFilters && (this.searchFilterForm.get('onyx_entity_id')?.value.length === 0 &&
      this.searchFilterForm.get('onyx_entity_name')?.value.length === 0 &&
      this.searchFilterForm.get('onyx_entity_address')?.value.length === 0)) {
      statusDisabled = true;
    }
    return statusDisabled;
  }

  private createFilterForm() {
    this.searchFilterForm = this.formBuilder.group({
      id : ['', [Validators.maxLength(100)]],
      onyx_entity_id : ['', [Validators.maxLength(100)]],
      onyx_entity_name: ['', [Validators.maxLength(100)]],
      onyx_entity_address: ['', [Validators.maxLength(100)]],
    });
  }
}
