import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IMasterRecordModel } from '@app/shared/interfaces/master-record-model.interface';

@Component({
  selector: 'app-bulk-card',
  templateUrl: './bulk-card.component.html',
  styleUrls: ['./bulk-card.component.scss']
})
export class BulkCardComponent implements OnInit, OnDestroy {
  @Input() item: IMasterRecordModel = null;
  @Output() selectedItem = new EventEmitter<IMasterRecordModel>();
  @Output() removedItem = new EventEmitter<IMasterRecordModel>();

  public showMore = false;
  public itemChecked = false;
  public pendingMatchesEntityCardSubs = new Subscription();

  constructor() {}

  ngOnDestroy(): void {
    this.pendingMatchesEntityCardSubs.unsubscribe();
  }

  ngOnInit(): void {}

  public selectLookupRecord(event, item: IMasterRecordModel) {
    const statusChck = event.currentTarget.checked;
    if (statusChck) {
      this.selectedItem.emit(item);
    } else {
      this.removedItem.emit(item);
    }
  }
}
