import { EnvConfig } from 'src/app/models/environment-configuration';

export const environmentDefaults: EnvConfig = {
  // Pioneer Server Endpoints
  appName: 'ORM',
  name: 'localhost',
  production: true,
  serviceUrl: 'http://localhost:3000',
  portalUrl: 'https://portal.dev.onyxcentersource.com',
  securityUrl: 'https://security.qa.onyxcentersource.com',
  tokenExpiredTime: 120,
  requestTimeout: 300000,
  portalRedirectTimer: 3000,
  portalLogout: '/j_spring_security_logout',

  // AWS Configuration
  region: 'us-east-1',
  awsUri: 'amazonaws.com',

  // Log handlers and log levels
  logServices: {
    consoleLogSubscriber: {
      logLevel: 3
    },
    httpLogSubscriber: {
      logLevel: 0
    }
  },

  // timeout to hide alert component
  alert: {
    alertSuccessTimeout: 3000,
    alertErrorTimeout: 0,
    alertWarningTimeout: 0,
    alertInfoTimeout: 2000,
  },
};
