import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-hard-delete',
  templateUrl: './hard-delete.component.html',
  styleUrls: ['./hard-delete.component.scss'],
  providers: [BsModalService]

})
export class HardDeleteComponent implements OnInit,  OnDestroy {

  @Output() closedModal = new EventEmitter<string>();

  public masterId = '';
  public confirmMasterId = '';

  constructor(private modalService: BsModalService) { }

  ngOnDestroy(): void {
    this.closedModal.emit('closed');
  }

  ngOnInit(): void {}

  public close(action) {
    this.closedModal.emit(action);
    this.modalService.hide();
  }

}
