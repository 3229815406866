import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtTokenManagementStore } from '@app-auth/services/jwt-token-management.store';
import { environment } from '@app-env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  isUserLoggedIn: boolean;

  constructor(private jwtTokenManagementStore: JwtTokenManagementStore) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.jwtTokenManagementStore.getToken();
    if (req.url.includes(environment.serviceUrl)) {
        if (this.jwtTokenManagementStore.isTokenExpired()) {
          throw new Error('token expired');
        }
        else {
        req = req.clone({
          url:  req.url,
          setHeaders: {
            Authorization: `${token}`
          }
        });
        }
      }
    return next.handle(req);
    }
}
