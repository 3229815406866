import { Component, OnInit, OnDestroy } from '@angular/core';
import { GpSpinnerService } from '@app-shared/services/gp-spinner.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss']
})
export class PageSpinnerComponent implements OnInit, OnDestroy {

  isLoading: Subject<boolean> = this.gpSpinnerService.isLoading();
  message: Subject<string> = this.gpSpinnerService.message();

  constructor(
    private gpSpinnerService: GpSpinnerService
  ) { }

  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {

  }

}


