import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CreateNewMasterInterface } from '@app-shared/interfaces/create-new-master.interface';
import { ConfirmationModel } from '@app-shared/models/alert-confimation.model';
import { CreateNewMasterModel } from '@app-shared/models/create-new-master.model';
import { AlertToastService } from '@app-shared/services/alert-toast.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-master',
  templateUrl: './create-new-master.component.html',
  styleUrls: ['./create-new-master.component.scss']
})
export class CreateNewMasterComponent implements OnInit {

  @Output() closedModal = new EventEmitter<ConfirmationModel>();
  @Output() saveModal = new EventEmitter<string>();

  // Modal Params
  public createMasterPreview;
  public createMasterErrorMessage = '';
  public createMasterDetail: CreateNewMasterModel;

  public isPreviewBlocked = false;
  public newMasterId = null;
  public reviewNotes = '';
  public createMasterSaved = false;

  constructor(private alertToastService: AlertToastService,
              private translateService: TranslateService, ) { }

  ngOnInit(): void {
    if (this.createMasterPreview) {
      this.newMasterId = this.createMasterPreview;
    } else {
      this.isPreviewBlocked = true;
    }
  }

  public save(){
    this.saveModal.emit(this.reviewNotes);
  }

  public close(){
    this.closedModal.emit({status: 'ok'});
  }

  public onClipboardCopy(entityId) {
    const params = {
      entityId
    };
    const message = this.translateService.instant('APP.PENDING_MATCHES.SUCCESS_COPY', params);
    this.alertToastService.info({alertToastMessage: message});
  }

  public setNewMasterToCopy() {
    this.createMasterSaved = true;
  }
}
