export enum MessageLevelModel {
  ERROR,
  WARN,
  INFO,
  SUCCESS
}

export class DetailErrorModel {
  isDetail = false;
  msg = '';
  errorDetail = '';

  constructor(data = null) {
    if (data) {
      this.isDetail = data.isDetail;
      this.msg = data.msg;
      this.errorDetail = data.errorDetail;
    }
  }
}
export class AlertToastModel {
  level: MessageLevelModel;
  cssClass: string;
  icoCss: string;
  timeOut: number;
  message: string;
  removed: boolean;
  displayThroughNavigation: boolean;
  autoClose: boolean;
  id: string;
  detail: DetailErrorModel = new DetailErrorModel();

  constructor(data = null)
  {
    this.id = Date.now().toString();

    if (data != null) {
      this.level = data.level;
      this.message = data.message;
      this.displayThroughNavigation = data.displayThroughNavigation;
      this.autoClose = data.autoClose;
      this.cssClass = data.cssClass;
      this.icoCss = data.icoCss;
      this.timeOut = data.timeOut;
      this.detail = data.detail || new DetailErrorModel();
    }
  }
}
