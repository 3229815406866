<div class="modal-header">
    <h5 class="modal-title" id="contactDeleteModalLabel">{{'APP.PENDING_MATCHES.REVIEW_REMARK_TITLE' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
   <div class="review-remark__changes form-group mb-4" *ngIf="changes.length > 0">
        <label>{{'APP.PENDING_MATCHES.REVIEW_REMARK.CHANGE_LOG' | translate}}</label>
        <div class="review-remark__changes_log">
            <code>
                <ng-container *ngIf="!isCompleted">
                    <span class="row mx-0" *ngFor="let item of changes">
                        {{item}}
                        <br />
                    </span>
                </ng-container>                
                <span *ngIf="isCompleted">
                    {{'APP.PENDING_MATCHES.REVIEW_REMARK.RECORD_VALIDATION_COMPLETED' | translate}}
                </span>
            </code>
        </div>
   </div>
   <div class="review-remark__posibilities" *ngIf="otherPossibilities.length > 0">
        <label>{{'APP.PENDING_MATCHES.REVIEW_REMARK.OTHER' | translate}}</label>
        <div class="review-remark__posibilities_tag">            
            <div class="filter-tag" *ngFor="let item of otherPossibilities">
                <span class="filter-tag-text">{{item}}</span>
            </div>
        </div>
   </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close('cancel')">{{actionClose | translate}}</button>
</div>