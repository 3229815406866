<mat-horizontal-stepper [linear]="'true'" #stepper (selectionChange)="selectionChange($event)">
    <!--STEP 1 : Select lookup records -->
    <mat-step label="Select lookup records" [stepControl]="firstFormGroup" *ngIf="isAbleStep1"> 
        <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
        </ng-template>    
        <ng-container>
            <div *ngIf="!stepStatus.one" class="color-error px-3">
                <strong class="mb-3">{{validationMessage.pendingLookupMissing}}</strong>
            </div>
            <div class="p-3" *ngIf="actualEntity">  
                <app-bulk-card-list  [onyxEntityId]="actualEntity.onyxEntityId" 
                                            [entityBulkCardListStore]="lookupStore"
                                            (setSelectedRecords)="setSelectedRecords($event)">
                    <ng-container description>
                        {{'APP.ENTITY_APPROVED.BULK_DELETE_MODAL.TITLE_DESCRIPTION' | translate}}
                    </ng-container>            
                </app-bulk-card-list>
            </div> 
        </ng-container>
    </mat-step>
    <!--STEP 2 : Search new Entity Master -->
    <mat-step label="Select new master" [stepControl]="secondFormGroup">
        <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
        </ng-template>
        <ng-container>
            <div *ngIf="!stepStatus.two" class="color-error px-3">
                <strong class="mb-3">{{validationMessage.searchNewMaster}}</strong>
            </div>
            <div class="p-3">
                <!-- -STEP 2.1: Search panel -->
                <ng-container #newMasterSearch>
                    <h3 class="d-inline" (click)="isCollapse.search = !isCollapse.search" role="button">   
                        <i class="far mr-2" [ngClass]="{'fa-chevron-up': !isCollapse.search, 'fa-chevron-down': isCollapse.search}"></i>
                        {{'APP.SEARCH_MASTER.TITLE_SEARCH' | translate}}
                    </h3>                        
                    <app-panel-new-entity-search class="mt-3"
                                                        [collapse]="isCollapse.search" 
                                                        [isAnimated]="true"
                                                        (search)="searchNewMaster($event)"></app-panel-new-entity-search>
                </ng-container>
                <!-- -STEP 2.2: Result list -->                   
                <ng-container #newMasterList>                        
                    <div [hidden]="!(masterListSearched && masterListSearched.length > 0)" class="mt-3">
                        <h3 class="d-inline" (click)="isCollapse.list = !isCollapse.list" role="button">   
                            <i class="far mr-2" [ngClass]="{'fa-chevron-up': !isCollapse.list, 'fa-chevron-down': isCollapse.list}"></i>
                            {{'APP.SEARCH_MASTER.TITLE_SEARCHED_LIST' | translate}} <small>({{totalSearchData}})</small>
                        </h3>     
                        <app-bulk-card-radio-list cardName="newMasterItem"
                                                        class="mt-3"
                                                        heightClass="small"
                                                        [collapse]="isCollapse.list"
                                                        [lookupList]="masterListSearched"
                                                        [lookupCount]="totalSearchData"
                                                        (setSelectedRecords)="setSelectedMaster($event)"
                                                        (setMoreRecords)="setMoreNewMasterRecords()">        
                        </app-bulk-card-radio-list> 
                    </div> 
                </ng-container>    
                <!-- -STEP 2.3: Selected master-->
                <ng-container #newMasterSelected *ngIf="newEntity">
                    <hr class="my-3"/>
                    <div class="mt-3">                            
                        <h3>{{'APP.SEARCH_MASTER.TITLE_SELECTED_ENTITY' | translate}}</h3>              
                        <app-entity-card-selected class="row col-8" [item]="newEntity" [isSelected]="'true'">
                            <ng-container entity-title>                    
                                <h3 class="card-title mr-3">
                                    {{newEntity.onyxEntityId}}
                                    <br>
                                    <small>{{newEntity.entityDisplayName}}</small>
                                </h3>
                            </ng-container>
                            <ng-container entity-content>
                                <p class="mb-1"><strong>{{'APP.ENTITY_APPROVED.LIST.HEADER_APPLICATION' | translate}}: </strong><small>{{newEntity.onyxApplicationName || '--'}}</small></p>
                                <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.GROUP' | translate}}: </strong><small>{{newEntity.onyxGroupId || '--'}}</small></p>
                                <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.SUBGROUP' | translate}}: </strong><small>{{newEntity.onyxSubgroupId || '--'}}</small></p>
                                <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.ADDRESS' | translate}}: </strong><small>{{newEntity.entityAddress1 || '--'}}</small></p>
                            </ng-container>               
                        </app-entity-card-selected>     
                   </div>     
                </ng-container>
                <!-- -STEP 2.2.1: Result empty list -->                                   
                <ng-container #newMasterEmptyList *ngIf="masterListSearched && masterListSearched.length <= 0">
                    <app-table-empty-result [message]="''" [marginStyle]="'mt-3'"></app-table-empty-result>
                </ng-container>                 
            </div>
        </ng-container>
    </mat-step>
    <!--STEP 3 : Review before saving-->
    <mat-step label="Confirm selection">
        <div class="p-3">
            <!-- STEP 3.1 : Review of lookup selected list -->
            <ng-container #newMasterReviewLookupSelected>
                <section>
                    <h2 class="mb-2">
                        <span *ngIf="lookupList && lookupList.length > 1">
                            {{'APP.SEARCH_MASTER.TITLE_SELECTED_ENTITIES' | translate}}
                            <small *ngIf="lookupList && lookupList.length > 1"> ({{lookupList.length}})</small>
                        </span>
                        <span *ngIf="lookupList && lookupList.length == 1">{{'APP.SEARCH_MASTER.TITLE_SELECTED_LOOKUP_ENTITY' | translate}}</span>                        
                        
                    </h2>
                    <div class=" mx-0" [ngClass]="{'review-lookup-selected': lookupList && lookupList.length > 4}" *ngIf="lookupList">
                        <div class="row mx-0">
                            <ng-container *ngFor="let item of lookupList">                           
                                <div class="col-md-3 pl-0">
                                    <app-entity-card-selected [item]="newEntity" [ngClass]="{'h-100': lookupList && lookupList.length > 1}">
                                        <ng-container entity-title>                    
                                            <h3 class="card-title mr-3 card-title--selected-entity">
                                                <strong>{{'APP.ENTITY_APPROVED.LIST.HEADER_SYSTEM_INFO.ENTITY_ID' | translate}}: </strong><small>{{item.onyxSystemEntityId || '--'}}</small>
                                            </h3>
                                        </ng-container>
                                        <ng-container entity-content>
                                            <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.GROUP' | translate}}: </strong><small>{{item.onyxGroupName || '--'}}</small></p>
                                            <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.SUBGROUP' | translate}}: </strong><small>{{item.onyxSubgroupName || '--'}}</small></p>
                                            <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.ADDRESS' | translate}}: </strong><small>{{item.entityAddress1 || '-'}}, {{item.entityAddress2 || '-'}}</small></p>
                                        </ng-container>               
                                    </app-entity-card-selected>
                                </div>
                            </ng-container>  
                        </div>
                    </div>
                </section>                    
            </ng-container>
            <hr class="my-4"/> 
             <!-- STEP 3.2 : Review of current and new entity master -->
            <ng-container #newMasterReviewEntityMasterSelected>
                <section>    
                    <div class="row">
                        <div class="col-md-6" *ngIf="actualEntity">
                            <h2 class="mb-2">
                                <span>{{'APP.SEARCH_MASTER.TITLE_EXISTING_ENTITY' | translate}}</span>
                            </h2>
                            <app-entity-card-selected [item]="actualEntity">
                                <ng-container entity-title>                    
                                    <h3 class="card-title mr-3 card-title--selected-entity">
                                        {{actualEntity.onyxEntityId}}
                                        <br>
                                        <small>{{actualEntity.entityDisplayName}}</small>
                                    </h3>
                                </ng-container>
                                <ng-container entity-content>
                                    <p class="mb-1"><strong>{{'APP.ENTITY_APPROVED.LIST.HEADER_APPLICATION' | translate}}: </strong><small>{{actualEntity.onyxApplicationName || '--'}}</small></p>
                                    <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.GROUP' | translate}}: </strong><small>{{actualEntity.onyxGroupId || '--'}}</small></p>
                                    <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.SUBGROUP' | translate}}: </strong><small>{{actualEntity.onyxSubgroupId || '--'}}</small></p>
                                    <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.ADDRESS' | translate}}: </strong><small>{{actualEntity.entityAddress1 || '--'}}</small></p>
                                </ng-container>               
                            </app-entity-card-selected>   
                        </div>
                        <div class="col-md-6" *ngIf="newEntity">
                            <h2 class="mb-2">{{'APP.SEARCH_MASTER.TITLE_SELECTED_ENTITY' | translate}}</h2>
                            <app-entity-card-selected [item]="newEntity" [isSelected]="'true'">
                                <ng-container entity-title>                    
                                    <h3 class="card-title mr-3 card-title--selected-entity">
                                        {{newEntity.onyxEntityId}}
                                        <br>
                                        <small>{{newEntity.entityDisplayName}}</small>
                                    </h3>
                                </ng-container>
                                <ng-container entity-content>
                                    <p class="mb-1"><strong>{{'APP.ENTITY_APPROVED.LIST.HEADER_APPLICATION' | translate}}: </strong><small>{{newEntity.onyxApplicationName || '--'}}</small></p>
                                    <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.GROUP' | translate}}: </strong><small>{{newEntity.onyxGroupId || '--'}}</small></p>
                                    <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.SUBGROUP' | translate}}: </strong><small>{{newEntity.onyxSubgroupId || '--'}}</small></p>
                                    <p class="mb-1"><strong>{{'APP.PENDING_MATCHES.MODAL.ADDRESS' | translate}}: </strong><small>{{newEntity.entityAddress1 || '--'}}</small></p>
                                </ng-container>               
                            </app-entity-card-selected>     
                        </div>
                    </div>
                </section>
            </ng-container>                
        </div>  
    </mat-step>
</mat-horizontal-stepper>