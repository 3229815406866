export class ActionPermissionsModel {
    successful: boolean;
    output: OutputModel;
    constructor(data = null) {
        if (data != null){
            this.successful = data.successful;
            this.output = new OutputModel(data.output);
        }
    }
}

export class OutputModel {
    id: string;
    groups: GroupModel[] = [];
    constructor(data = null){
        if (data != null) {
            this.id = data.id;

            for (const group of data.groups) {
                this.groups.push(new GroupModel(group));
            }
        }
    }
}

export class GroupModel {
    id: string;
    policies: PoliciesModel[] = [];
    constructor(data = null) {
        if (data != null) {
            this.id = data.id;
            for (const policy of data.policies) {
                this.policies.push(new PoliciesModel(policy));
            }
        }
    }
}

export class PoliciesModel {
    id: string;
    app: string;
    action: string;
    constructor(data = null) {
        if (data != null) {
            this.id = data.id;
            this.app = data.app;
            this.action = data.action;
        }
    }
}
