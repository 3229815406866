import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrmStatusReviewS } from '@app-shared/const/orm-pending-list';
import { ReviewRemarkModel } from '@app-shared/models/review-remark.model';

@Component({
  selector: 'app-review-remark',
  templateUrl: './review-remark.component.html',
  styleUrls: ['./review-remark.component.scss']
})
export class ReviewRemarkComponent implements OnInit {

  @Input() item: ReviewRemarkModel = new ReviewRemarkModel();
  @Output() closedModal = new EventEmitter<string>();

  public actionClose = 'APP.BUTTON.CLOSE';

  public changes = [];
  public otherPossibilities = [];
  public isCompleted = false;
  constructor() { }


  ngOnInit(): void {
    this.createInformation();
  }

  public close(action) {
    this.closedModal.emit(action);
  }

  public createInformation() {
    const rawData = this.item.reviewRemark.split(';');
    this.isCompleted = this.item.reviewStatus === OrmStatusReviewS.COMPLETED;
    let otherPossibility = '';
    /* tslint:disable:prefer-for-of */
    for (let index = 0; index < rawData.length; index++) {
      const element = rawData[index];
      if (element.includes('Other possible matches')) {
        otherPossibility = element.replace('Other possible matches :', '');
      } else {
        this.changes.push(element.trim());
      }
    }

    if (otherPossibility && otherPossibility.length > 0) {
      this.otherPossibilities = otherPossibility.trim().split(',');
    }
  }

}
