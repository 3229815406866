<ng-container>
    <p class="mb-4">
        <ng-content select="[description]"></ng-content>
    </p>
    <div class=" search row bulk-card-list--container-card" [ngClass]="lookupList && lookupList.length > 2 ? heightClass: ''">            
        <div *ngFor="let item of lookupList" class="col-md-6 mb-3">
            <div class="card mb-2 h-100" *ngIf="item" [ngClass]="{'selected': actualSeletectRecord === item.uniqId}">              
                <div class="custom-control custom-radio mb-0 row mx-0 py-2 radio-list">
                    <div class="col-md-10">
                        <input type="radio" 
                                class="custom-control-input c-pointer" 
                                [id]="item.uniqId"
                                [name]="cardName"
                                [checked]="item.uniqId === actualSeletectRecord"
                                (click)="selectedItem(item)">
                        <label class="custom-control-label c-pointer" [for]="item.uniqId">
                            <ng-container  [ngTemplateOutlet]="!isExternalDetail ? entityDefault : ''"
                                           [ngTemplateOutletContext]="{item:item}"></ng-container>      
                            
                            <ng-container  [ngTemplateOutlet]="isExternalDetail ? itemDetailTemplate : ''" 
                                           [ngTemplateOutletContext]="{item:item}"></ng-container>         
                        </label>
                    </div>                                              
                </div>
            </div>
        </div>
        <div class="row col-12 justify-content-center" *ngIf="lookupList && lookupList.length < lookupCount">
            <button (click)="showMoreRecords()" class="btn show-more--button">Show more</button>            
        </div>  
    </div>    
</ng-container>

<ng-template #entityDefault let-item="item">
    <div>
        <h3 class="mr-2 mb-0">{{item.onyxEntityId}}</h3>
        <small class="mr-2">{{item.entityDisplayName}}</small>
    </div>
    <ng-container>
        <small class="mr-1">
            <strong>
                <span *ngIf="showInfoName">{{'APP.FILTER_APPLICATION' | translate}}</span>
                <span *ngIf="!showInfoName">{{'APP.FILTER_APPLICATION_ID' | translate}}: </span>
            </strong>
        </small>
        <small class="mr-2" *ngIf="showInfoName">{{item.onyxApplicationName || '-'}}</small>
        <small class="mr-2" *ngIf="!showInfoName">{{item.onyxApplicationId || '-'}}</small>
        <br>
        <small class="mr-1"> 
            <strong>
                <span *ngIf="showInfoName">{{'APP.FILTER_GROUP_NAME' | translate}}: </span>
                <span *ngIf="!showInfoName">{{'APP.FILTER_GROUP_ID' | translate}}: </span>
            </strong>
        </small>
        <small class="mr-2">            
            <span *ngIf="showInfoName">{{item.onyxGroupName || '-'}}</span>
            <span *ngIf="!showInfoName">{{item.onyxGroupId || '-'}}</span>
        </small>
        <br>
        <small class="mr-1"> 
            <strong>
                <span *ngIf="showInfoName">{{'APP.FILTER_SUBGROUP_NAME' | translate}}: </span>
                <span *ngIf="!showInfoName">{{'APP.FILTER_SUBGROUP_ID' | translate}}: </span>
            </strong>
        </small>
        <small class="mr-2">            
            <span *ngIf="showInfoName">{{item.onyxSubgroupName || '-'}}</span>
            <span *ngIf="!showInfoName">{{item.onyxSubgroupId || '-'}}</span>
        </small>
        <br>
    </ng-container> 
    <ng-container>
        <small><strong class="mr-1">{{'APP.FILTER_ADDRESS' | translate}}: </strong></small>
        <small class="mr-2">{{item.entityAddress1 || '-'}}, {{item.entityAddress2 || '-'}}</small>
    </ng-container> 
</ng-template>

