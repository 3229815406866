import { ALERT_DANGER,
        ALERT_DANGER_ICO,
        ALERT_DANGER_TIMEOUT,
        ALERT_INFO,
        ALERT_INFO_ICO,
        ALERT_INFO_TIMEOUT,
        ALERT_SUCCESS,
        ALERT_SUCCESS_ICO,
        ALERT_SUCCESS_TIMEOUT,
        ALERT_WARNING,
        ALERT_WARNING_ICO,
        ALERT_WARNING_TIMEOUT } from '@app-shared/const/toast-type.const';

import { MessageLevelModel } from './alert-toast.model';

export class AlertStructureModel {
    public className = '';
    public ico = '';
    public timeOut = '';
    public autoClose = false;

    constructor(data = null) {
      if (data) {
        this.className = data.className;
        this.ico = data.ico;
        this.timeOut = data.timeOut;
        this.autoClose = data.autoClose;
      }
    }
  }

export class AlertToastTypeModel {
    typeList: AlertStructureModel[] = [];

    constructor() {
      this.createToastTypeList();
    }

    public getTypeList() {
      return this.typeList;
    }

    private createToastTypeList() {
        this.typeList[MessageLevelModel.WARN] = new AlertStructureModel({
          className: ALERT_WARNING,
          ico: ALERT_WARNING_ICO,
          timeOut: ALERT_WARNING_TIMEOUT
        });

        this.typeList[MessageLevelModel.ERROR] = new AlertStructureModel({
          className: ALERT_DANGER,
          ico: ALERT_DANGER_ICO,
          timeOut: ALERT_DANGER_TIMEOUT
        });

        this.typeList[MessageLevelModel.SUCCESS] = new AlertStructureModel({
          className: ALERT_SUCCESS,
          ico: ALERT_SUCCESS_ICO,
          timeOut: ALERT_SUCCESS_TIMEOUT,
          autoClose: true
        });

        this.typeList[MessageLevelModel.INFO] = new AlertStructureModel({
          className: ALERT_INFO,
          ico: ALERT_INFO_ICO,
          timeOut: ALERT_INFO_TIMEOUT,
          autoClose: true
        });
    }
  }
